/**
 * @generated SignedSource<<4774d5b3464f3e23635b62e05a2f9532>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsDeleteDocumentKVPMutation$variables = {
  id: number;
  key: string;
};
export type MutationsDeleteDocumentKVPMutation$data = {
  readonly deleteDocumentKVP: boolean;
};
export type MutationsDeleteDocumentKVPMutation = {
  response: MutationsDeleteDocumentKVPMutation$data;
  variables: MutationsDeleteDocumentKVPMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteDocumentKVP",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsDeleteDocumentKVPMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsDeleteDocumentKVPMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c3c76c7e2e6aa174d5d20e2da8c6669",
    "id": null,
    "metadata": {},
    "name": "MutationsDeleteDocumentKVPMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsDeleteDocumentKVPMutation(\n  $id: Int!\n  $key: String!\n) {\n  deleteDocumentKVP(id: $id, key: $key)\n}\n"
  }
};
})();

(node as any).hash = "40f04620403baeef91d752e97c243e83";

export default node;
