import { HomeOutlined, SearchOutlined, ToolOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import styled from '@emotion/styled'
import { Avatar, Button, Layout, Menu, Popover, Typography } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { MSGraphClient } from 'src/Modules/Utilities/Authorization/MSGraphClient'
import { useMyAccount } from 'src/Modules/Utilities/Authorization/UseMyAccount'
import UseRole from 'src/Modules/Utilities/Authorization/UseRole'

/**
 * Navbar for the interface
 * @returns The NavBar JSX element
 */
export default function NavMenu() {
  const { instance } = useMsal()
  const account = useMyAccount()
  const isDeveloper = UseRole('Developer')
  const key = UseKey()

  return (
    <NavMenuHeader>
      <Menu mode='horizontal' defaultSelectedKeys={key ? [key] : undefined}>
        <Menu.Item key='home' icon={<HomeOutlined />}>
          <Link to='/'>Home</Link>
        </Menu.Item>
        <Menu.Item key='search' icon={<SearchOutlined />}>
          <Link to='/search'>Search</Link>
        </Menu.Item>
        {isDeveloper && (
          <SubMenu
            key='development'
            icon={<ToolOutlined />}
            title='Development'
          >
            <Menu.Item key='Schemas'>
              <Link to='/development/schemas'>Schemas</Link>
            </Menu.Item>
            <Menu.Item key='Create'>
              <Link to='/development/create'>Create Schema</Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
      <Popover
        trigger='click'
        arrowPointAtCenter
        placement='bottomRight'
        content={
          <ContentWrapper>
            <UserNameWrapper>
              <UserNameText type='secondary'>Welcome,</UserNameText>
              <UserNameText>{account?.name}</UserNameText>
            </UserNameWrapper>

            <Button onClick={() => instance.logoutPopup()}>Logout</Button>
          </ContentWrapper>
        }
      >
        <ProfilePicture name={account?.name} />
      </Popover>
    </NavMenuHeader>
  )
}

/**
 * Determine the key to use according to the path.
 * @returns The key to use
 */
function UseKey() {
  const location = useLocation()
  const paths = location.pathname.split('/')

  switch (paths[1]) {
    case '':
    case 'home':
      return 'home'
    case 'search':
      return 'search'
    case 'development':
      switch (paths[2]) {
        case 'schemas':
          return 'Schemas'
        case 'create':
          return 'Create'
        default:
          return 'Development'
      }
  }
}

/**
 * Renders an avater of the user
 * @param props Avatar props.
 * @returns A profile picture
 */
function ProfilePicture(props: {
  name?: string
  className?: string
  onClick?: () => void
}) {
  const [pf, setPf] = useState<any>()

  useEffect(() => {
    MSGraphClient.api('/me/photo/$value')
      .get()
      .then((pf) => setPf(URL.createObjectURL(pf)))
      .catch(() => {
        //Ignore when not pf
      })
  }, [])

  return (
    <AvatarWrapper onClick={props.onClick} className={props.className}>
      <Avatar size={'large'} src={pf}>
        {props.name && props.name[0]}
      </Avatar>
    </AvatarWrapper>
  )
}

// Styles
const AvatarWrapper = styled.div`
  cursor: pointer;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 10px;
`

const UserNameWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 10px;
`

const UserNameText = styled(Typography.Text)`
  line-height: 1;
  white-space: 'nowrap';
`

const NavMenuHeader = styled(Layout.Header)`
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: #141414;
  align-items: center;
  border-bottom: 1px solid #303030;
`
