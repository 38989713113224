/**
 * @generated SignedSource<<4bbef0fb80f14ea2bca7db8d2e603f97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetSchemaNameQuery$variables = {
  ids?: ReadonlyArray<number> | null;
  key?: string | null;
};
export type QueriesGetSchemaNameQuery$data = {
  readonly schemaKVPs: ReadonlyArray<{
    readonly schemaId: number;
    readonly value: string;
  }>;
};
export type QueriesGetSchemaNameQuery = {
  response: QueriesGetSchemaNameQuery$data;
  variables: QueriesGetSchemaNameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      },
      {
        "kind": "Variable",
        "name": "schemaIds",
        "variableName": "ids"
      }
    ],
    "concreteType": "SchemaKvp",
    "kind": "LinkedField",
    "name": "schemaKVPs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schemaId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetSchemaNameQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetSchemaNameQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f0b622e61752516ec8eafd79db495146",
    "id": null,
    "metadata": {},
    "name": "QueriesGetSchemaNameQuery",
    "operationKind": "query",
    "text": "query QueriesGetSchemaNameQuery(\n  $ids: [Int!]\n  $key: String\n) {\n  schemaKVPs(schemaIds: $ids, key: $key) {\n    schemaId\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "04ae75cafb7d516e6201d76d098555ac";

export default node;
