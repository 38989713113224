import DataLoader from 'dataloader'

/**
 * Create a dataloader without cache using the given function.
 * @param LoadValuesFunction The dataloader function
 * @returns A dataloader instance
 */
export function CreateDataloader<TValue, TResult>(
  LoadValuesFunction: (ids: number[]) => Promise<readonly TValue[]>,
  GetId: (value: TValue) => [number, TResult]
) {
  return new DataLoader<number, TResult | null>(
    async (keys) => {
      const values = await LoadValuesFunction([...keys])
      const dict = Object.fromEntries(
        values.map((value) => GetId(value as TValue))
      )
      return keys.map((key) => dict[key] ?? null)
    },
    { cache: false }
  )
}
