import styled from '@emotion/styled'
import React from 'react'

import { StyledLink } from 'src/Modules/Common/Components/LinkWithParam'

/**
 * Create title element for a card
 * @returns The card title
 */
export function CardTitle(props: { name: string | number; to: string }) {
  return (
    <CardTitleWrapper>
      <StyledCardName to={props.to}>{props.name}</StyledCardName>
    </CardTitleWrapper>
  )
}

const CardTitleWrapper = styled.div`
  overflow: hidden;
`

const StyledCardName = styled(StyledLink)`
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`
