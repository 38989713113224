/**
 * @generated SignedSource<<9e14fd8a4e57d83430d2d2180687f068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentsWithReferences2Query$variables = {
  at?: string | null;
  documentIds?: ReadonlyArray<number> | null;
};
export type QueriesDocumentsWithReferences2Query$data = {
  readonly documents: ReadonlyArray<{
    readonly _id: number;
    readonly referencedByDocumentVersions: ReadonlyArray<{
      readonly document: {
        readonly _id: number;
      };
    }>;
    readonly schema: {
      readonly _id: number;
    };
    readonly versionAt: {
      readonly _id: number;
      readonly referencingDocuments: ReadonlyArray<{
        readonly _id: number;
      }>;
      readonly referencingSchemas: ReadonlyArray<{
        readonly _id: number;
      }>;
    } | null;
  }>;
};
export type QueriesDocumentsWithReferences2Query = {
  response: QueriesDocumentsWithReferences2Query$data;
  variables: QueriesDocumentsWithReferences2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "at"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      (v2/*: any*/),
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "documentIds"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Schema",
        "kind": "LinkedField",
        "name": "schema",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "referencedByDocumentVersions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "versionAt",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "referencingSchemas",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "referencingDocuments",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentsWithReferences2Query",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentsWithReferences2Query",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "5a92a5752bad36df7e1f5569d6dc6576",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentsWithReferences2Query",
    "operationKind": "query",
    "text": "query QueriesDocumentsWithReferences2Query(\n  $documentIds: [Int!]\n  $at: DateTime\n) {\n  documents(ids: $documentIds, at: $at) {\n    _id\n    schema {\n      _id\n    }\n    referencedByDocumentVersions(at: $at) {\n      document {\n        _id\n      }\n    }\n    versionAt(at: $at) {\n      _id\n      referencingSchemas {\n        _id\n      }\n      referencingDocuments {\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "925b3cd555bf601308f15db4ba580af2";

export default node;
