/**
 * @generated SignedSource<<781484c791348d4a902c2dfb5b01452a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentWithSchemaQuery$variables = {
  at?: string | null;
  id: number;
};
export type QueriesDocumentWithSchemaQuery$data = {
  readonly documentVersions: ReadonlyArray<{
    readonly document: {
      readonly schema: {
        readonly _id: number;
        readonly versions: ReadonlyArray<{
          readonly json: any;
        }>;
      };
    };
    readonly json: any;
  } | null>;
};
export type QueriesDocumentWithSchemaQuery = {
  response: QueriesDocumentWithSchemaQuery$data;
  variables: QueriesDocumentWithSchemaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "at"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "json",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      (v2/*: any*/),
      {
        "items": [
          {
            "kind": "Variable",
            "name": "documentIds.0",
            "variableName": "id"
          }
        ],
        "kind": "ListValue",
        "name": "documentIds"
      }
    ],
    "concreteType": "DocumentVersion",
    "kind": "LinkedField",
    "name": "documentVersions",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "schema",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v2/*: any*/)
                ],
                "concreteType": "SchemaVersion",
                "kind": "LinkedField",
                "name": "versions",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentWithSchemaQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentWithSchemaQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b789cf6eee8bc5f9ee298f61755c9488",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentWithSchemaQuery",
    "operationKind": "query",
    "text": "query QueriesDocumentWithSchemaQuery(\n  $id: Int!\n  $at: DateTime\n) {\n  documentVersions(documentIds: [$id], at: $at) {\n    json\n    document {\n      schema {\n        _id\n        versions(at: $at) {\n          json\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28ed10a5481ecdb5a33ae15c24d4a2f8";

export default node;
