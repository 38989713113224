import { useMemo } from 'react'

/**
 * A hook to memoize number(s).
 * @param oneOrMany The id or ids to turn into an id array.
 */
export default function UseOneOrMany(oneOrMany: number | number[]) {
  return useMemo(() => OneOrMany(oneOrMany), [oneOrMany])
}

/**
 * Turn a number or number array into an id array.
 * @param oneOrMany The id or ids to turn into an id array.
 * @returns The id or ids as an array.
 */
export function OneOrMany(oneOrMany: number | number[]): number[] {
  return typeof oneOrMany === 'number' ? [oneOrMany] : oneOrMany
}
