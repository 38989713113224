/**
 * @generated SignedSource<<2c0d0cdbb413c5cf7c42fc19230be30c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesSimInformationQuery$variables = {
  documentId: string;
};
export type QueriesSimInformationQuery$data = {
  readonly getSimInformationFromId: {
    readonly deviceName: string;
    readonly group: string | null;
    readonly iccid: string;
    readonly imsi: string;
    readonly msisdn: string | null;
    readonly simPlan: string | null;
    readonly simState: string;
    readonly usageData: any;
    readonly usageSms: any;
    readonly usageSmsPmt: any;
    readonly usageVoice: any;
  };
};
export type QueriesSimInformationQuery = {
  response: QueriesSimInformationQuery$data;
  variables: QueriesSimInformationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "concreteType": "SimInformation",
    "kind": "LinkedField",
    "name": "getSimInformationFromId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iccid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imsi",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "simState",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "simPlan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "group",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usageSms",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usageData",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usageVoice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usageSmsPmt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "msisdn",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSimInformationQuery",
    "selections": (v1/*: any*/),
    "type": "GetSimInformationFromId",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesSimInformationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5c44aa53ad8a18db9d461e9f8e1cbfb8",
    "id": null,
    "metadata": {},
    "name": "QueriesSimInformationQuery",
    "operationKind": "query",
    "text": "query QueriesSimInformationQuery(\n  $documentId: ID!\n) {\n  getSimInformationFromId(documentId: $documentId) {\n    iccid\n    imsi\n    simState\n    simPlan\n    deviceName\n    group\n    usageSms\n    usageData\n    usageVoice\n    usageSmsPmt\n    msisdn\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cf3fd666eb53c6a8e833b657286d500";

export default node;
