import React from 'react'

import { useDocumentName } from 'src/Modules/Home/Hooks/DocumentName'

interface IDocumentId {
  documentId: number
}

/**
 * A document id column
 * @returns A column for document Ids.
 */
export default function DocumentIdColumn<T extends IDocumentId>(
  documentIds: number[]
) {
  return {
    title: 'Document',
    dataIndex: 'documentId',
    key: 'documentId',
    render: (documentId: number) => <DocumentRender documentId={documentId} />,
    filters: documentIds.map((docId) => {
      return {
        value: docId, //TODO validate this works
        text: <DocumentRender documentId={docId} />
      }
    }),
    onFilter: (value: any, record: T) => record.documentId === value
  }
}

/**
 * render the name of the document.
 * @param documentId the id of the document
 * @returns a document name
 */
function DocumentRender({ documentId }: { documentId: number }) {
  const { data: documentName } = useDocumentName(documentId, { suspense: true })
  return <p>{documentName?.name?.toString() ?? documentId.toString()}</p>
}
