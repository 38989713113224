import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'

import { QueriesDocumentQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentQuery.graphql'
import { QueriesDocumentVersionQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentVersionQuery.graphql'
import { QueriesDocumentVersionsFromSchemaQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentVersionsFromSchemaQuery.graphql'
import { QueriesDocumentVersionsWithReferencesQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentVersionsWithReferencesQuery.graphql'
import { QueriesDocumentWithSchemaQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentWithSchemaQuery.graphql'
import { QueriesDocumentsQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentsQuery.graphql'
import { QueriesDocumentsWithReferences2Query } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesDocumentsWithReferences2Query.graphql'
import { QueriesGetActiveDocumentIdsQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesGetActiveDocumentIdsQuery.graphql'
import { QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery.graphql'
import { QueriesReferencesFromDocumentQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesReferencesFromDocumentQuery.graphql'
import { QueriesSchemaPermissionsQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemaPermissionsQuery.graphql'
import { QueriesSchemaQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemaQuery.graphql'
import { QueriesSchemaVersionsWithReferencesAndDocumentsQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemaVersionsWithReferencesAndDocumentsQuery.graphql'
import { QueriesSchemasQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemasQuery.graphql'
import { QueriesSchemasWithReferencesAndDocumentsQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesSchemasWithReferencesAndDocumentsQuery.graphql'
import { QueriesValidateQuery } from 'src/Modules/Graphql/DocumentManager/__generated__/QueriesValidateQuery.graphql'
import { DMRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Get all schema ids valid at this moment
 * @returns The ids of the currently valid schemas
 */
export async function GetSchemas() {
  const query = graphql`
    query QueriesSchemasQuery($at: DateTime) {
      schemas(at: $at) {
        _id
      }
    }
  `
  return (await fetchQuery<QueriesSchemasQuery>(
    DMRelayEnvironment,
    query,
    { at: null },
    { fetchPolicy: 'network-only' }
  ).toPromise())!.schemas
}

/**
 * Validate the given jsons to the given schema
 * @param schemaId Id of the schema
 * @param jsons The json objects to validate
 * @returns A list op optional errors.
 */
export async function ValidateDocument(schemaId: number, jsons: any[]) {
  const query = graphql`
    query QueriesValidateQuery($schemaId: Int!, $jsons: [JsonScalar!]!) {
      validateDocuments(schemaId: $schemaId, jsons: $jsons)
    }
  `
  return (await fetchQuery<QueriesValidateQuery>(DMRelayEnvironment, query, {
    schemaId: schemaId,
    jsons: jsons
  }).toPromise())!.validateDocuments
}

/**
 * Get a single schema's current json
 * @param schemaId Id of the schema
 * @returns The json schema of the given id
 */
export async function GetSchemaJson(schemaId: number) {
  const query = graphql`
    query QueriesSchemaQuery($schemaId: Int!, $at: DateTime) {
      schemaVersions(schemaIds: [$schemaId], at: $at) {
        json
      }
    }
  `
  return (await fetchQuery<QueriesSchemaQuery>(DMRelayEnvironment, query, {
    schemaId: schemaId,
    at: null
  }).toPromise())!.schemaVersions[0]?.json
}

/**
 * Get a schemas including references to and from it and the documents that implement them
 * @param ids Ids of the schemas
 * @returns The schemas including document ids
 */
export async function GetSchemasWithReferencesAndDocuments(ids: number[]) {
  const query = graphql`
    query QueriesSchemasWithReferencesAndDocumentsQuery(
      $ids: [Int!]
      $now: DateTime
    ) {
      schemas(ids: $ids, at: $now) {
        _id
        documents(at: $now) {
          _id
        }
        versionAt(at: $now) {
          _id
          referencingSchemas(at: $now) {
            _id
          }
        }
        referencedByDocumentVersions(at: $now) {
          document {
            _id
          }
        }
        referencedBySchemaVersions(at: $now) {
          schema {
            _id
          }
        }
      }
    }
  `
  return (await fetchQuery<QueriesSchemasWithReferencesAndDocumentsQuery>(
    DMRelayEnvironment,
    query,
    {
      ids: ids,
      now: null
    }
  ).toPromise())!.schemas
}

/**
 * Get the schema version schema with references using the given filters.
 * @param args The filters to use
 * @returns The schema version schemas with references
 */
export async function GetSchemaVersionsWithReferencesAndDocuments(args: {
  schemaIds?: number[]
  orderBy?: string[]
}) {
  const query = graphql`
    query QueriesSchemaVersionsWithReferencesAndDocumentsQuery(
      $schemaIds: [Int!]
      $at: DateTime
      $orderBy: [String!]
    ) {
      schemaVersions(schemaIds: $schemaIds, at: $at, orderBy: $orderBy) {
        schema {
          _id
          documents(at: $at) {
            _id
          }
          versionAt(at: $at) {
            _id
            referencingSchemas(at: $at) {
              _id
            }
          }
          referencedByDocumentVersions(at: $at) {
            document {
              _id
            }
          }
          referencedBySchemaVersions(at: $at) {
            schema {
              _id
            }
          }
        }
      }
    }
  `
  return (await fetchQuery<QueriesSchemaVersionsWithReferencesAndDocumentsQuery>(
    DMRelayEnvironment,
    query,
    {
      at: null,
      ...args
    }
  ).toPromise())!.schemaVersions.map((schemaVersion) => schemaVersion!.schema)
}

/**
 * Get a schemas including references to and from it and the documents that implement them
 * @param ids Ids of the schemas
 * @returns The schemas including document ids
 */
export async function GetDocumentsWithReferences(ids: number[]) {
  const query = graphql`
    query QueriesDocumentsWithReferences2Query(
      $documentIds: [Int!]
      $at: DateTime
    ) {
      documents(ids: $documentIds, at: $at) {
        _id
        schema {
          _id
        }
        referencedByDocumentVersions(at: $at) {
          document {
            _id
          }
        }
        versionAt(at: $at) {
          _id
          referencingSchemas {
            _id
          }
          referencingDocuments {
            _id
          }
        }
      }
    }
  `
  return (await fetchQuery<QueriesDocumentsWithReferences2Query>(
    DMRelayEnvironment,
    query,
    {
      documentIds: ids,
      at: null
    }
  ).toPromise())!.documents
}

/**
 * Get the document version document with references using the given filters.
 * @param args The filters to use
 * @returns The document version documents with references
 */
export async function GetDocumentVersionsWithReferences(args: {
  documentIds?: number[]
  schemaIds?: number[]
  orderBy?: string[]
}) {
  const query = graphql`
    query QueriesDocumentVersionsWithReferencesQuery(
      $documentIds: [Int!]
      $schemaIds: [Int!]
      $at: DateTime
      $orderBy: [String!]
    ) {
      documentVersions(
        documentIds: $documentIds
        schemaIds: $schemaIds
        at: $at
        orderBy: $orderBy
      ) {
        document {
          _id
          schema {
            _id
          }
          referencedByDocumentVersions(at: $at) {
            document {
              _id
            }
          }
          versionAt(at: $at) {
            _id
            referencingSchemas {
              _id
            }
            referencingDocuments {
              _id
            }
          }
        }
      }
    }
  `

  return (await fetchQuery<QueriesDocumentVersionsWithReferencesQuery>(
    DMRelayEnvironment,
    query,
    {
      at: null,
      ...args
    }
  ).toPromise())!.documentVersions!.map(
    (documentVersion) => documentVersion!.document
  )
}

/**
 * Get all documents ids valid now
 * @param schemaIds Optional list of schema ids the documents must implement
 * @returns The documents satisfying the query
 */
export async function GetDocuments(schemaIds?: number[]) {
  const query = graphql`
    query QueriesDocumentsQuery($schemaIds: [Int!], $at: DateTime) {
      documents(schemaIds: $schemaIds, at: $at) {
        _id
      }
    }
  `

  return (await fetchQuery<QueriesDocumentsQuery>(DMRelayEnvironment, query, {
    at: null,
    schemaIds: schemaIds
  }).toPromise())!.documents
}

/**
 * Get document based on id including their schema json
 * @param documentId Id of documents to load
 * @returns The document including schema
 */
export async function GetDocumentJson(documentId: number) {
  const query = graphql`
    query QueriesDocumentQuery($ids: [Int!], $at: DateTime) {
      documents(ids: $ids) {
        versionAt(at: $at) {
          json
        }
      }
    }
  `
  return (
    await fetchQuery<QueriesDocumentQuery>(DMRelayEnvironment, query, {
      ids: [documentId],
      at: null
    }).toPromise()
  )?.documents[0].versionAt
}

/**
 * Get document based on id including their schema json
 * @param documentId Id of documents to load
 * @returns The document including schema
 */
export async function GetDocumentWithSchema(documentId: number) {
  const query = graphql`
    query QueriesDocumentWithSchemaQuery($id: Int!, $at: DateTime) {
      documentVersions(documentIds: [$id], at: $at) {
        json
        document {
          schema {
            _id
            versions(at: $at) {
              json
            }
          }
        }
      }
    }
  `
  return (
    await fetchQuery<QueriesDocumentWithSchemaQuery>(
      DMRelayEnvironment,
      query,
      {
        id: documentId,
        at: null
      }
    ).toPromise()
  )?.documentVersions[0]
}

/**
 * Get currently active documents based on their id
 * @param documentIds Ids of documents to load
 * @returns Currently active documents
 */
export async function GetActiveDocumentIds(documentIds: number[]) {
  const query = graphql`
    query QueriesGetActiveDocumentIdsQuery($ids: [Int!], $at: DateTime) {
      documents(ids: $ids, at: $at) {
        _id
      }
    }
  `
  return (
    await fetchQuery<QueriesGetActiveDocumentIdsQuery>(
      DMRelayEnvironment,
      query,
      {
        ids: documentIds,
        at: null
      }
    ).toPromise()
  )?.documents.map((x) => x._id)
}

/**
 * Gets the latest version of a list of documents
 * @param documentIds Ids of the document to retrieve
 * @returns The latest versions of the specified documents.
 */
export async function GetDocumentVersionsFromSchema(schemaIds: number[]) {
  const query = graphql`
    query QueriesDocumentVersionsFromSchemaQuery(
      $schemaIds: [Int!]
      $at: DateTime
    ) {
      documentVersions(schemaIds: $schemaIds, at: $at) {
        json
        document {
          _id
        }
      }
    }
  `

  return (
    await fetchQuery<QueriesDocumentVersionsFromSchemaQuery>(
      DMRelayEnvironment,
      query,
      {
        schemaIds: schemaIds,
        at: null
      }
    ).toPromise()
  )?.documentVersions
}

/**
 * Get a document and its referencing document versions with their schemas
 * @param ids Id of the document
 * @returns The document including referencing document versions
 */
export async function GetReferencesFromDocument(id: number) {
  const query = graphql`
    query QueriesReferencesFromDocumentQuery($ids: [Int!], $now: DateTime) {
      documents(ids: $ids, at: $now) {
        referencedByDocumentVersions(at: $now) {
          document {
            _id
            schema {
              _id
            }
          }
        }
      }
    }
  `
  return (
    await fetchQuery<QueriesReferencesFromDocumentQuery>(
      DMRelayEnvironment,
      query,
      {
        ids: [id],
        now: null
      }
    ).toPromise()
  )?.documents[0]
}

/**
 * Gets the latest version of a given document
 * @param documentId Id of the document to retrieve
 * @returns The latest version of the specified document.
 */
export async function GetDocumentVersion(documentId: number) {
  const query = graphql`
    query QueriesDocumentVersionQuery($documentIds: [Int!], $at: DateTime) {
      documentVersions(documentIds: $documentIds, at: $at) {
        json
      }
    }
  `

  return (
    await fetchQuery<QueriesDocumentVersionQuery>(DMRelayEnvironment, query, {
      documentIds: [documentId],
      at: null
    }).toPromise()
  )?.documentVersions[0]
}

/** Get who has read or write access to the schema
 * @param id Id of the schema
 * @returns The permissions of the schema
 */
export async function GetSchemaPermissions(id: number) {
  const query = graphql`
    query QueriesSchemaPermissionsQuery($schemaIds: [Int!], $at: DateTime) {
      schemas(ids: $schemaIds, at: $at) {
        readAccess
        writeAccess
      }
    }
  `

  return await fetchQuery<QueriesSchemaPermissionsQuery>(
    DMRelayEnvironment,
    query,
    {
      schemaIds: [id],
      at: null
    }
  ).toPromise()
}

/**
 * Retrieve document jsons and references for export.
 * @param documentIds Id of the document to retrieve.
 * @param referencingSchemas Schema Ids of the referencing documents.
 * @param referencedBySchemas Schema Ids of the referenced by documents.
 * @returns The referencing and referenced by documents with the document json.
 */
export async function GetDocumentsJsonAndReferencesBySchemaId(
  documentIds: number[],
  referencingSchemas: number[] = [],
  referencedBySchemas: number[] = []
) {
  const query = graphql`
    query QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery(
      $documentIds: [Int!]
      $referencingSchemaIds: [Int!]
      $referencedBySchemaIds: [Int!]
      $at: DateTime
    ) {
      documents(ids: $documentIds) {
        _id
        versionAt(at: $at) {
          json

          referencingDocuments(at: $at, schemaIds: $referencingSchemaIds) {
            _id
            schema {
              _id
            }
          }
        }
        referencedByDocumentVersions(
          at: $at
          schemaIds: $referencedBySchemaIds
        ) {
          document {
            _id
            schema {
              _id
            }
          }
        }
      }
    }
  `

  return (await fetchQuery<QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery>(
    DMRelayEnvironment,
    query,
    {
      documentIds: documentIds,
      referencingSchemaIds: referencingSchemas,
      referencedBySchemaIds: referencedBySchemas,
      at: null
    }
  ).toPromise())!.documents
}
