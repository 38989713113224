/**
 * @generated SignedSource<<0117e051c83275d8696d66dcfc0ebb82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetDocumentNameQuery$variables = {
  ids?: ReadonlyArray<number> | null;
  key?: string | null;
};
export type QueriesGetDocumentNameQuery$data = {
  readonly documentKVPs: ReadonlyArray<{
    readonly documentId: number;
    readonly value: string;
  }>;
};
export type QueriesGetDocumentNameQuery = {
  response: QueriesGetDocumentNameQuery$data;
  variables: QueriesGetDocumentNameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "ids"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "DocumentKvp",
    "kind": "LinkedField",
    "name": "documentKVPs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetDocumentNameQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetDocumentNameQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4a8d93bea3f9826b1a425e503b8dd8ff",
    "id": null,
    "metadata": {},
    "name": "QueriesGetDocumentNameQuery",
    "operationKind": "query",
    "text": "query QueriesGetDocumentNameQuery(\n  $ids: [Int!]\n  $key: String\n) {\n  documentKVPs(documentIds: $ids, key: $key) {\n    documentId\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b0444cb32374ffd861d3f3d0dfcb3eb";

export default node;
