import { fromPairs, groupBy, mapValues } from 'lodash'
import { useMemo } from 'react'

import { useDocuments } from 'src/Modules/Home/Hooks/Document'
import { useSchemaNames } from 'src/Modules/Home/Hooks/SchemaName'

/**
 * useGroupDocumentsBySchemaName
 * @param documentIds the document ids to load.
 * @returns The document ids.
 */
export function useGroupDocumentsBySchemaName(
  documentIds: number[]
): [string, number[]][] {
  const { data: documents } = useDocuments(documentIds, { suspense: true })

  const schemaIds = useMemo(
    () => documents!.map((document) => document!.schemaId),
    [documents]
  )

  const { data: schemaNames } = useSchemaNames(schemaIds ?? [], {
    suspense: true,
    enabled: schemaIds !== undefined
  })

  const schemaNameDict = fromPairs(
    schemaNames!.map((schemaName) => [schemaName?.schemaId, schemaName?.value])
  )

  // Group on the schema name
  const schemaWithDocuments = mapValues(
    groupBy(
      documents!.map((document) => document),
      (document) => schemaNameDict[document!.schemaId]!
    ),
    (documentTuples) => documentTuples.map((document) => document!.id!)
  )
  return Object.entries(schemaWithDocuments)
}
