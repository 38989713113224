import { EditOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import React, { useState } from 'react'

import { SingleInputModal } from 'src/Modules/Common/Components/SingleInputModal'

export type RenameButtonProps = {
  name?: string
  onSuccess?: (newName?: string) => void
  className?: string
}

/**
 * Button that can be used to rename a schema or document
 * @param props Props including schema/document id, current name and success callback
 * @returns A button with a rename icon
 */
function RenameButton(
  props: { onOk: (value?: string) => void } & RenameButtonProps
) {
  const [modalShows, setModalShows] = useState<boolean>(false)

  return (
    <>
      <Tooltip title='Rename'>
        <EditOutlined
          className={props.className}
          onClick={(e) => {
            e.stopPropagation()
            setModalShows(true)
          }}
        />
      </Tooltip>
      <SingleInputModal
        title='Rename'
        visible={modalShows}
        onOk={(value) => handleOk(value)}
        onCancel={handleCancel}
        initialInput={props.name}
      />
    </>
  )

  /** Method called when the rename modal is submitted */
  async function handleOk(value?: string) {
    props.onOk(value)
    setModalShows(false)
  }

  /** Method called when the rename modal is cancelled */
  function handleCancel() {
    setModalShows(false)
  }
}

export const BigRenameButton = styled(RenameButton)`
  font-size: 20px;
  margin-left: 10px;
  color: gray;
`
