import { GetSchemaValue } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { UseCustomQuery } from 'src/Modules/Home/Hooks/QueryClient'
import { IntegrationsKeyName } from 'src/Modules/Utilities/Environment'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'

/**
 * A hook to send sms to the eview integration
 * @param schemaId the schemaId of the current document
 * @param valueNames the name that is supposed to be in the kvp array values
 * @returns undefined if the document is not an eview button or a function to send an sms
 */
export default function useIntegrations(schemaId: number) {
  const { data } = UseCustomQuery(
    ['integration', schemaId],
    async (): Promise<string[]> => {
      const kvpValues = schemaId
        ? (await GetSchemaValue(schemaId, IntegrationsKeyName))?.value
        : undefined

      try {
        return !kvpValues ? [] : JSON.parse(kvpValues)
      } catch (e: any) {
        NotificationError(
          'Failed to parse the integrations',
          'Unable to determine the tabs to use for this schema',
          e
        )
        return []
      }
    },
    { suspense: true }
  )

  /**
   * Check if the integration with the given name is listed.
   * @param name The name of the integration
   * @returns Wether the integration is active for the schema
   */
  function contains(name: string) {
    return data!.includes(name)
  }

  return {
    hasEviewSms: contains('EviewSms'),
    hasEviewForce: contains('EviewForce'),
    hasLoggingStatus: contains('LoggingStatus'),
    hasOliviaSim: contains('OliviaSim'),
    hasLoggingCoordinates: contains('LoggingCoordinates'),
    hasLoggingMacAddresses: contains('LoggingMacAddresses'),
    hasImport: contains('Import'),
    hasExport: contains('Export')
  }
}
