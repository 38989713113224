/**
 * @generated SignedSource<<be0eb8f8f1b7e6188a80206a6a5c1ccb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetActiveDocumentIdsQuery$variables = {
  at?: string | null;
  ids?: ReadonlyArray<number> | null;
};
export type QueriesGetActiveDocumentIdsQuery$data = {
  readonly documents: ReadonlyArray<{
    readonly _id: number;
  }>;
};
export type QueriesGetActiveDocumentIdsQuery = {
  response: QueriesGetActiveDocumentIdsQuery$data;
  variables: QueriesGetActiveDocumentIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      },
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetActiveDocumentIdsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesGetActiveDocumentIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2ba377430bd355153c197c051e618692",
    "id": null,
    "metadata": {},
    "name": "QueriesGetActiveDocumentIdsQuery",
    "operationKind": "query",
    "text": "query QueriesGetActiveDocumentIdsQuery(\n  $ids: [Int!]\n  $at: DateTime\n) {\n  documents(ids: $ids, at: $at) {\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "33f894fab8e501fb634d039375007c75";

export default node;
