import { DeleteOutlined } from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import React, { ComponentProps, useState } from 'react'

/**
 * Button that can be used to delete a schema or document
 * @param resourceName name of the resource to delete
 * @param onConfirmDelete function to call when the delete is confirmed
 * @param buttonProps props of the icon
 * @returns A button with a delete icon
 */
export function DeleteButton({
  resourceName,
  onConfirmDelete,
  buttonProps
}: Readonly<{
  resourceName: string
  onConfirmDelete: () => void
  buttonProps: ComponentProps<typeof DeleteOutlined>
}>) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <>
      <Tooltip title='Delete'>
        <DeleteOutlined
          {...buttonProps}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </Tooltip>
      <ConfirmDeletionModal
        resourceName={resourceName}
        isOpen={isDeleteModalOpen}
        onConfirm={() => onConfirmDelete()}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
}

/**
 * Modal to confirm deletion
 * @param props props of the modal
 * @returns A modal to confirm deletion
 */
export function ConfirmDeletionModal({
  resourceName,
  isOpen,
  onConfirm,
  onClose
}: Readonly<{
  resourceName: string
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}>) {
  return (
    <Modal
      title='Are you sure to delete this resource?'
      visible={isOpen}
      onOk={() => {
        onConfirm()
        onClose()
      }}
      destroyOnClose
      onCancel={() => onClose()}
    >
      {resourceName}
    </Modal>
  )
}
