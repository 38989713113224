import styled from '@emotion/styled'
import { groupBy } from 'lodash'
import React, { useState } from 'react'

import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { CloneDocumentButton } from 'src/Modules/Home/Components/NodeOptionButtons/CloneButton/CloneDocumentButton'
import { DeleteDocumentButton } from 'src/Modules/Home/Components/NodeOptionButtons/DeleteButton/DeleteDocumentButton'
import { RefreshButton } from 'src/Modules/Home/Components/NodeOptionButtons/RefreshButton'
import { CategoryTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/CategoryTreeNode'
import { MyTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/MyTreeNode'
import { TreeProjection } from 'src/Modules/Home/Components/Tree/TreeWithSelect'
import { useDocumentName } from 'src/Modules/Home/Hooks/DocumentName'

/** Tree Node representing a document */
export function DocumentTreeNode(props: {
  documentId: number
  projections?: TreeProjection[]
}) {
  const route = UseRoute()

  const [lastUpdated, setLastUpdated] = useState(Date.now())

  const { data: documentName } = useDocumentName(props.documentId, {
    suspense: true
  })

  return (
    <MyTreeNode
      title={documentName?.name?.toString() ?? props.documentId.toString()}
      to={`/${route}/document/${props.documentId}`}
      options={NodeOptions()}
      hasChildren={props.projections !== undefined}
      getChildren={GetChildren}
    />
  )

  /**
   * Get the children of the document.
   * @returns The children for this document tree node.
   */
  function GetChildren() {
    const groupedProjections = groupBy(
      props.projections,
      (proj) => proj.categoryName ?? ''
    )

    return Object.keys(groupedProjections).map((proj) => (
      <CategoryTreeNode
        name={proj}
        key={proj}
        projections={groupedProjections[proj]}
        parentDocumentId={props.documentId}
        lastUpdated={lastUpdated}
      />
    ))
  }

  /** Create options for this tree node */
  function NodeOptions(): JSX.Element[] {
    return [
      <StyledCloneDocumentButton key='Clone' documentId={props.documentId} />,
      <StyledDeleteDocumentButton key='Delete' documentId={props.documentId} />,
      // Only allow a refresh on the document itself if there are direct children, otherwise it's easier to reload from the category
      ...(props.projections &&
      props.projections.some((projection) => !projection.categoryName)
        ? [
            <StyledRefreshButton
              key='Refresh'
              onClick={() => setLastUpdated(Date.now())}
            />
          ]
        : [])
    ]
  }
}

const StyledCloneDocumentButton = styled(CloneDocumentButton)`
  margin-right: 5px;
`

const StyledDeleteDocumentButton = styled(DeleteDocumentButton)`
  margin-right: 5px;
`

const StyledRefreshButton = styled(RefreshButton)`
  margin-right: 5px;
`
