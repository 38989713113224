import { graphql } from 'babel-plugin-relay/macro'
import { fetchQuery } from 'react-relay'

import { GetIndex } from 'src/Modules/Common/Logic/GetIndex'
import { QueriesGetDocumentKvpsQuery } from 'src/Modules/Graphql/MetaDataManager/__generated__/QueriesGetDocumentKvpsQuery.graphql'
import { QueriesGetDocumentNameQuery } from 'src/Modules/Graphql/MetaDataManager/__generated__/QueriesGetDocumentNameQuery.graphql'
import { QueriesGetDocumentsRegexQuery } from 'src/Modules/Graphql/MetaDataManager/__generated__/QueriesGetDocumentsRegexQuery.graphql'
import { QueriesGetSchemaKvpsQuery } from 'src/Modules/Graphql/MetaDataManager/__generated__/QueriesGetSchemaKvpsQuery.graphql'
import { QueriesGetSchemaNameQuery } from 'src/Modules/Graphql/MetaDataManager/__generated__/QueriesGetSchemaNameQuery.graphql'
import { MMRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'
import { DocumentName } from 'src/Modules/Utilities/DocumentName'

/**
 * Get the names of schemas
 * @param ids The ids of the schemas
 * @returns The schema kvps containing the names
 */
export async function GetSchemaNames(ids: number[]) {
  return GetSchemaValues(ids, 'name')
}

/**
 * Get the values of schemas
 * @param ids The ids of the schemas
 * @param key The key to find
 * @returns The schemas kvps containing the values
 */
export async function GetSchemaValues(ids: number[], key: string) {
  const query = graphql`
    query QueriesGetSchemaNameQuery($ids: [Int!], $key: String) {
      schemaKVPs(schemaIds: $ids, key: $key) {
        schemaId
        value
      }
    }
  `

  return (await fetchQuery<QueriesGetSchemaNameQuery>(
    MMRelayEnvironment,
    query,
    { ids: ids, key: key },
    { fetchPolicy: 'network-only' }
  ).toPromise())!.schemaKVPs
}

/**
 * Get the values of schema
 * @param ids The id of the schema
 * @param key The key to find
 * @returns The schema kvp containing the value
 */
export async function GetSchemaValue(id: number, key: string) {
  return GetIndex(await GetSchemaValues([id], key), 0)
}

/**
 * Get all key value pairs of a single schema.
 * @param id The id of the schema.
 * @returns All key value pairs of the schema.
 */
export async function GetSchemaKvps(id: number) {
  const query = graphql`
    query QueriesGetSchemaKvpsQuery($ids: [Int!]) {
      schemaKVPs(schemaIds: $ids) {
        schemaId
        key
        value
      }
    }
  `

  return (
    await fetchQuery<QueriesGetSchemaKvpsQuery>(
      MMRelayEnvironment,
      query,
      { ids: [id] },
      { fetchPolicy: 'network-only' }
    ).toPromise()
  )?.schemaKVPs
}

/**
 * Get the names of documents
 * @param ids The ids of the documents
 * @returns The document kvps containing the names
 */
export async function GetDocumentNames(ids: number[]) {
  return (await GetDocumentValues(ids, 'name'))?.map((name) => ({
    id: name.documentId,
    name: new DocumentName(name.value)
  }))
}

/**
 * Get the values of documents
 * @param ids The ids of the documents
 * @param key The key to find
 * @returns The document kvps containing the values
 */
async function GetDocumentValues(ids: number[], key: string) {
  const query = graphql`
    query QueriesGetDocumentNameQuery($ids: [Int!], $key: String) {
      documentKVPs(documentIds: $ids, key: $key) {
        documentId
        value
      }
    }
  `

  return (await fetchQuery<QueriesGetDocumentNameQuery>(
    MMRelayEnvironment,
    query,
    { ids: ids, key: key },
    { fetchPolicy: 'network-only' }
  ).toPromise())!.documentKVPs
}

/**
 * Get the values of document
 * @param ids The id of the document
 * @param key The key to find
 * @returns The document kvp containing the value
 */
export async function GetDocumentValue(id: number, key: string) {
  return GetIndex(await GetDocumentValues([id], key), 0)
}

/**
 * Get all key value pairs of a single document.
 * @param id The id of the document.
 * @returns All key value pairs of the document.
 */
export async function GetDocumentKvps(id: number) {
  const query = graphql`
    query QueriesGetDocumentKvpsQuery($ids: [Int!]) {
      documentKVPs(documentIds: $ids) {
        documentId
        key
        value
      }
    }
  `

  return (
    await fetchQuery<QueriesGetDocumentKvpsQuery>(
      MMRelayEnvironment,
      query,
      { ids: [id] },
      { fetchPolicy: 'network-only' }
    ).toPromise()
  )?.documentKVPs
}

/**
 * Get ui schema of schema if it exists
 * @param id Id of the schema
 */
export async function GetUiSchema(id: number) {
  const response = await GetSchemaValue(id, 'uiSchema')
  return response !== undefined ? JSON.parse(response.value) : response
}

/**
 * Get document ids based on certain regex.
 * @param regex Regex to match on.
 * @returns List of document ids of document matching the regex.w
 */
export async function GetDocumentsWithRegex(regex: string) {
  const query = graphql`
    query QueriesGetDocumentsRegexQuery($key: String!, $regex: String!) {
      documentKVPs(key: $key, valueRegex: $regex) {
        documentId
      }
    }
  `

  return (
    await fetchQuery<QueriesGetDocumentsRegexQuery>(
      MMRelayEnvironment,
      query,
      { key: 'name', regex: regex },
      { fetchPolicy: 'network-only' }
    ).toPromise()
  )?.documentKVPs.map((x) => x.documentId)
}
