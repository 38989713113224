import styled from '@emotion/styled'
import { Select } from 'antd'
import _, { groupBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FullHeightDiv } from 'src/Modules/Common/Containers/FullHeightDiv'
import { GetDocumentVersionsFromSchema } from 'src/Modules/Graphql/DocumentManager/Queries'
import { SchemaDocumentTree } from 'src/Modules/Home/Components/Tree/CategoryDocumentTree'
import { useDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { UseCustomQuery } from 'src/Modules/Home/Hooks/QueryClient'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { treeParam, TreeSchemaId } from 'src/Modules/Utilities/Environment'
import { executeSafelyAsync } from 'src/Modules/Utilities/ErrorHandler'

export type TreeProjection = {
  jsonLogic: any
  addButtons?: number[]
  categoryName?: string
  childNodes?: TreeProjection[]
}

type TreeConfig = {
  id: number
  name: string
  filters: TreeProjection[]
}

/**
 * Tree select to determine the root config for the tree.
 * @returns A component to render the tree and a select.
 */
export function TreeWithSelect() {
  // Get the documents that describe the tree filters
  const { data: trees, isSuccess: treesLoaded } = UseCustomQuery(
    'treeSchemas',
    async () => await GetDocumentVersionsFromSchema([TreeSchemaId]),
    { suspense: true }
  )
  // Get the names of these configurations
  const { data: documentNames, isSuccess } = useDocumentNames(
    trees?.map((config) => config!.document._id) ?? [],
    { enabled: treesLoaded, suspense: true }
  )

  const [treeOptions, setTreeOptions] = useState<TreeConfig[]>()

  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTree = searchParams.get(treeParam)

  // Update the current tree id to the one selected as default in the url.
  const treeId =
    treeOptions?.find((x) => x.name === defaultTree)?.id ?? treeOptions?.[0].id

  const [rootProjections, setRootFilter] = useState<TreeProjection[]>()

  // Get the available tree configurations only once
  useEffect(() => {
    // Only run this function on first load, when trees are not set yet.
    if (treeOptions || rootProjections || !isSuccess) return

    // Save the names and configurations
    const TreeOptions = _.zip(trees!, documentNames!).map(([tree, name]) => ({
      id: tree!.document._id,
      name: name!.name.toString(),
      filters: tree?.json
    }))

    setTreeOptions((prev) => (prev ? prev : TreeOptions))
    setRootFilter((prev) => (prev ? prev : TreeOptions[0]?.filters))
  }, [documentNames, isSuccess, rootProjections, treeOptions, trees])

  // Load the root schemas or documents every time the tree is changed
  useEffect(() => {
    /** Async function wrapper */
    async function effectFunction() {
      if (!treeId) return

      const treeOption =
        treeOptions?.find((option) => option.id === treeId) ?? treeOptions?.[0]

      if (!treeOption) return

      // Get the selected tree configuration
      const rootSchemas = treeOption.filters

      setRootFilter(rootSchemas)
    }

    executeSafelyAsync(async () => await effectFunction())
  }, [treeId, treeOptions])

  /**
   * Update the tree config when a different tree is selected.
   * @param newOption The current selected option.
   */
  function OnTreeFilterChange(newOption: number) {
    const name = treeOptions?.find((option) => option.id === newOption)?.name
    if (!name) return

    searchParams.set(treeParam, name)
    setSearchParams(searchParams)
  }

  // Group options using text before unescaped -
  const optionGroups = useMemo(() => {
    if (!treeOptions) return undefined

    // Split all options on "-" go get name and group, group optional.
    const splittedTreeOptions = treeOptions.map((option) => {
      const [, group, name] = option.name
        .matchAll(/(?:(.*)-)?(.*)/g)
        .next().value
      return { id: option.id, group, name }
    })

    // Turn groups into an array.
    return Object.entries(
      groupBy(splittedTreeOptions, ({ group }) => group ?? 'ungrouped')
    )
  }, [treeOptions])

  return (
    <GridDiv>
      <Select<number>
        loading={!treeOptions}
        onChange={(option) => OnTreeFilterChange(option)}
        value={treeId}
        listHeight={512}
        style={{
          width: '100%',
          padding: '4px 3px'
        }}
      >
        {optionGroups?.map(([group, options]) => (
          <Select.OptGroup key={group} label={group}>
            {options?.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
      {rootProjections === undefined ? (
        <CenteredCustomSpinner />
      ) : (
        <SchemaDocumentTree rootProjections={rootProjections} />
      )}
    </GridDiv>
  )
}

const GridDiv = styled(FullHeightDiv)`
  display: grid;
  grid-template-rows: auto 1fr;
`
