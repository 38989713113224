import { Alert } from 'antd'
import React from 'react'

import { CenteringDiv } from 'src/Modules/Utilities/Components/Centering/CenteringDiv'

/**
 * Centered message indicating unhandled error.
 * @returns A centered error message
 */
export default function CenteredError({
  type,
  description,
  onClose
}: {
  type?: 'error' | 'success' | 'info' | 'warning'
  description?: string
  onClose?: () => void
}) {
  return (
    <CenteringDiv>
      <Alert
        type={type ?? 'error'}
        message='Unhandled error'
        description={
          description ??
          'There was an unhandled error, please let the development team know.'
        }
        closable={onClose !== undefined}
        onClose={onClose}
      />
    </CenteringDiv>
  )
}
