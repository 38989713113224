/**
 * @generated SignedSource<<2100468fd0b2981ca07a3cec8fc4f832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesSchemasWithReferencesAndDocumentsQuery$variables = {
  ids?: ReadonlyArray<number> | null;
  now?: string | null;
};
export type QueriesSchemasWithReferencesAndDocumentsQuery$data = {
  readonly schemas: ReadonlyArray<{
    readonly _id: number;
    readonly documents: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly referencedByDocumentVersions: ReadonlyArray<{
      readonly document: {
        readonly _id: number;
      };
    }>;
    readonly referencedBySchemaVersions: ReadonlyArray<{
      readonly schema: {
        readonly _id: number;
      };
    }>;
    readonly versionAt: {
      readonly _id: number;
      readonly referencingSchemas: ReadonlyArray<{
        readonly _id: number;
      }>;
    } | null;
  }>;
};
export type QueriesSchemasWithReferencesAndDocumentsQuery = {
  response: QueriesSchemasWithReferencesAndDocumentsQuery$data;
  variables: QueriesSchemasWithReferencesAndDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "now"
  }
],
v1 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "now"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = [
  (v2/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      (v1/*: any*/),
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "Schema",
    "kind": "LinkedField",
    "name": "schemas",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "versionAt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "referencingSchemas",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "referencedByDocumentVersions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "referencedBySchemaVersions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "schema",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSchemasWithReferencesAndDocumentsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesSchemasWithReferencesAndDocumentsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "33092277efec409756cf18acc33a5050",
    "id": null,
    "metadata": {},
    "name": "QueriesSchemasWithReferencesAndDocumentsQuery",
    "operationKind": "query",
    "text": "query QueriesSchemasWithReferencesAndDocumentsQuery(\n  $ids: [Int!]\n  $now: DateTime\n) {\n  schemas(ids: $ids, at: $now) {\n    _id\n    documents(at: $now) {\n      _id\n    }\n    versionAt(at: $now) {\n      _id\n      referencingSchemas(at: $now) {\n        _id\n      }\n    }\n    referencedByDocumentVersions(at: $now) {\n      document {\n        _id\n      }\n    }\n    referencedBySchemaVersions(at: $now) {\n      schema {\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3351d6a5379b8482c550e4681041d98";

export default node;
