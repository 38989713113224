/**
 * @generated SignedSource<<5e0769efdd82a3b352aaee9b7f5ac793>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsActivateSimCardMutation$variables = {
  documentId: string;
};
export type MutationsActivateSimCardMutation$data = {
  readonly activateSimCard: string;
};
export type MutationsActivateSimCardMutation = {
  response: MutationsActivateSimCardMutation$data;
  variables: MutationsActivateSimCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "kind": "ScalarField",
    "name": "activateSimCard",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsActivateSimCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsActivateSimCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "768afac317ae9a544ce88da0bde709ba",
    "id": null,
    "metadata": {},
    "name": "MutationsActivateSimCardMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsActivateSimCardMutation(\n  $documentId: ID!\n) {\n  activateSimCard(documentId: $documentId)\n}\n"
  }
};
})();

(node as any).hash = "db143048e85fa012845767e71786684c";

export default node;
