/**
 * Error to be thrown when an schema/document (version) id is not found.
 */
export class NotFoundError extends Error {
  id: number

  constructor(id: number) {
    super('Not found')

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NotFoundError.prototype)

    this.id = id
  }
}
