/**
 * @generated SignedSource<<a1da8fa61d5a66969884aebfe3c2b4df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentVersionQuery$variables = {
  at?: string | null;
  documentIds?: ReadonlyArray<number> | null;
};
export type QueriesDocumentVersionQuery$data = {
  readonly documentVersions: ReadonlyArray<{
    readonly json: any;
  } | null>;
};
export type QueriesDocumentVersionQuery = {
  response: QueriesDocumentVersionQuery$data;
  variables: QueriesDocumentVersionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      },
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "documentIds"
      }
    ],
    "concreteType": "DocumentVersion",
    "kind": "LinkedField",
    "name": "documentVersions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "json",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentVersionQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentVersionQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1841481114675759d5fd03eae62733f3",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentVersionQuery",
    "operationKind": "query",
    "text": "query QueriesDocumentVersionQuery(\n  $documentIds: [Int!]\n  $at: DateTime\n) {\n  documentVersions(documentIds: $documentIds, at: $at) {\n    json\n  }\n}\n"
  }
};
})();

(node as any).hash = "6170351c0dbb801c779279ac951d57fd";

export default node;
