/**
 * @generated SignedSource<<5ed0149907b6e9d14d186562c6234bed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentsQuery$variables = {
  at?: string | null;
  schemaIds?: ReadonlyArray<number> | null;
};
export type QueriesDocumentsQuery$data = {
  readonly documents: ReadonlyArray<{
    readonly _id: number;
  }>;
};
export type QueriesDocumentsQuery = {
  response: QueriesDocumentsQuery$data;
  variables: QueriesDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaIds"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      },
      {
        "kind": "Variable",
        "name": "schemaIds",
        "variableName": "schemaIds"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1984d98f88f89fdae0b433c40321c9ae",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentsQuery",
    "operationKind": "query",
    "text": "query QueriesDocumentsQuery(\n  $schemaIds: [Int!]\n  $at: DateTime\n) {\n  documents(schemaIds: $schemaIds, at: $at) {\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f5610f39e2840bab52202a358940976";

export default node;
