import { CalendarOutlined } from '@ant-design/icons'
import { DatePicker, Tooltip } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import moment from 'moment'
import React from 'react'

interface IDateTime {
  dateTime: string
}

type momentrange = {
  start: string | undefined
  end: string | undefined
}

/**
 * Get properties for ant date range picker
 * @returns Properties for the dropdown and filtering
 */
export default function DateColumn<T extends IDateTime>(): ColumnType<T> {
  return {
    onFilter: (value: any, record: T) => {
      const recordMoment = moment(record.dateTime)
      const range: momentrange = JSON.parse(value)
      return (
        recordMoment.isSameOrAfter(moment(range.start)) &&
        recordMoment.isSameOrBefore(moment(range.end))
      )
    },
    filterIcon: (filtered: boolean) => (
      <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend'],
    sorter: (first: T, second: T) =>
      moment(first.dateTime).valueOf() - moment(second.dateTime).valueOf(),
    render: (value: string) => {
      const m = moment(value)
      return (
        <Tooltip title={m.toString()}>
          {m.format('YYYY/MM/DD HH:mm:ss')}
        </Tooltip>
      )
    },
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownProps) => (
      <DatePicker.RangePicker
        showTime
        onChange={(
          dates: [moment.Moment | null, moment.Moment | null] | null,
          _: [string, string]
        ) => {
          // If dates are selected in the datepicker, set start and end date to picked dates
          if (dates) {
            const range: momentrange = {
              start: dates[0]?.toISOString(),
              end: dates[1]?.toISOString()
            }

            setSelectedKeys([JSON.stringify(range)]) // Calls the antd onFilter function
            confirm() // Only closes the dropdown
            return
          }

          // Otherwise, clear all filters
          clearFilters!()
        }}
      />
    )
  }
}
