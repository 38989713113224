/**
 * @generated SignedSource<<320067df6298fdf7d6f97edf2647baad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesSchemaPermissionsQuery$variables = {
  at?: string | null;
  schemaIds?: ReadonlyArray<number> | null;
};
export type QueriesSchemaPermissionsQuery$data = {
  readonly schemas: ReadonlyArray<{
    readonly readAccess: ReadonlyArray<string> | null;
    readonly writeAccess: ReadonlyArray<string> | null;
  }>;
};
export type QueriesSchemaPermissionsQuery = {
  response: QueriesSchemaPermissionsQuery$data;
  variables: QueriesSchemaPermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaIds"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      },
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "schemaIds"
      }
    ],
    "concreteType": "Schema",
    "kind": "LinkedField",
    "name": "schemas",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "readAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "writeAccess",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSchemaPermissionsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesSchemaPermissionsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8e74efcc519cc6f9f467272ddeac5ea0",
    "id": null,
    "metadata": {},
    "name": "QueriesSchemaPermissionsQuery",
    "operationKind": "query",
    "text": "query QueriesSchemaPermissionsQuery(\n  $schemaIds: [Int!]\n  $at: DateTime\n) {\n  schemas(ids: $schemaIds, at: $at) {\n    readAccess\n    writeAccess\n  }\n}\n"
  }
};
})();

(node as any).hash = "f45605a59ae80955f89a35b9edef1e2c";

export default node;
