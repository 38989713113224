/**
 * @generated SignedSource<<fb84f1a40dcaf914c5666fb8a003adbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesSchemasQuery$variables = {
  at?: string | null;
};
export type QueriesSchemasQuery$data = {
  readonly schemas: ReadonlyArray<{
    readonly _id: number;
  }>;
};
export type QueriesSchemasQuery = {
  response: QueriesSchemasQuery$data;
  variables: QueriesSchemasQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "at"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      }
    ],
    "concreteType": "Schema",
    "kind": "LinkedField",
    "name": "schemas",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSchemasQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesSchemasQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9af8c358b953ef5d1ced19e26365ecf5",
    "id": null,
    "metadata": {},
    "name": "QueriesSchemasQuery",
    "operationKind": "query",
    "text": "query QueriesSchemasQuery(\n  $at: DateTime\n) {\n  schemas(at: $at) {\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e54a1e813cade0d9dc3c118b096ea2fb";

export default node;
