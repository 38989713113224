/**
 * @generated SignedSource<<1aad6eff96aafaee33ab88236e3329c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesPaginatedMacAddressesQuery$variables = {
  after?: string | null;
  documentIds?: ReadonlyArray<number> | null;
  first?: number | null;
};
export type QueriesPaginatedMacAddressesQuery$data = {
  readonly paginatedMacAdresses: {
    readonly items: ReadonlyArray<{
      readonly _id: any;
      readonly dateTime: string;
      readonly documentId: number;
      readonly macAddress: string;
    }> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  } | null;
};
export type QueriesPaginatedMacAddressesQuery = {
  response: QueriesPaginatedMacAddressesQuery$data;
  variables: QueriesPaginatedMacAddressesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "documentIds"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "ModelMacAddressConnection",
    "kind": "LinkedField",
    "name": "paginatedMacAdresses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MacAddress",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "documentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "macAddress",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesPaginatedMacAddressesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesPaginatedMacAddressesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b3436134a041489ff3b150249388c84f",
    "id": null,
    "metadata": {},
    "name": "QueriesPaginatedMacAddressesQuery",
    "operationKind": "query",
    "text": "query QueriesPaginatedMacAddressesQuery(\n  $documentIds: [Int!]\n  $first: Int\n  $after: String\n) {\n  paginatedMacAdresses(documentIds: $documentIds, first: $first, after: $after) {\n    items {\n      _id\n      documentId\n      dateTime\n      macAddress\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29b5810e039aa8017652504865787f3a";

export default node;
