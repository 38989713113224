/**
 * @generated SignedSource<<69b02c9c56761835f4ee59e710e79d29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetDocumentsRegexQuery$variables = {
  key: string;
  regex: string;
};
export type QueriesGetDocumentsRegexQuery$data = {
  readonly documentKVPs: ReadonlyArray<{
    readonly documentId: number;
  }>;
};
export type QueriesGetDocumentsRegexQuery = {
  response: QueriesGetDocumentsRegexQuery$data;
  variables: QueriesGetDocumentsRegexQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regex"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      },
      {
        "kind": "Variable",
        "name": "valueRegex",
        "variableName": "regex"
      }
    ],
    "concreteType": "DocumentKvp",
    "kind": "LinkedField",
    "name": "documentKVPs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetDocumentsRegexQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetDocumentsRegexQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "06ab5586a8e918d0d0a7940969bdff7c",
    "id": null,
    "metadata": {},
    "name": "QueriesGetDocumentsRegexQuery",
    "operationKind": "query",
    "text": "query QueriesGetDocumentsRegexQuery(\n  $key: String!\n  $regex: String!\n) {\n  documentKVPs(key: $key, valueRegex: $regex) {\n    documentId\n  }\n}\n"
  }
};
})();

(node as any).hash = "afa706d1493a18bdc4c191bd222ff750";

export default node;
