import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'

import { MutationsForceConfigurationMutation } from 'src/Modules/Graphql/Eview/__generated__/MutationsForceConfigurationMutation.graphql'
import { MutationsForceUpdateMutation } from 'src/Modules/Graphql/Eview/__generated__/MutationsForceUpdateMutation.graphql'
import { MutationsSendSmsMutation } from 'src/Modules/Graphql/Eview/__generated__/MutationsSendSmsMutation.graphql'
import { EVRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Send an SMS to the eview button
 * @param documentId Document id of the button
 * @returns Phone number of the button
 */
export async function SendSms(documentId: number): Promise<string | undefined> {
  const mutation = graphql`
    mutation MutationsSendSmsMutation($documentId: ID!) {
      sendSms(documentId: $documentId)
    }
  `

  return new Promise((resolve, reject) => {
    commitMutation<MutationsSendSmsMutation>(EVRelayEnvironment, {
      mutation: mutation,
      variables: { documentId: documentId.toString() },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.sendSms)
      }
    })
  })
}

/**
 * Force firmware update of button
 * @param documentId Document id of the button
 * @returns Boolean that indicates success
 */
export async function ForceUpdate(
  documentId: number
): Promise<boolean | undefined> {
  const mutation = graphql`
    mutation MutationsForceUpdateMutation($id: ID!) {
      forceUpdate(id: $id)
    }
  `
  return new Promise((resolve, reject) => {
    commitMutation<MutationsForceUpdateMutation>(EVRelayEnvironment, {
      mutation: mutation,
      variables: { id: documentId.toString() },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.forceUpdate)
      }
    })
  })
}

/**
 * Force configuration update of button
 * @param documentId Document id of the button
 * @returns Boolean that indicates success
 */
export async function ForceConfiguration(
  documentId: number
): Promise<boolean | undefined> {
  const mutation = graphql`
    mutation MutationsForceConfigurationMutation($id: ID!) {
      forceConfiguration(id: $id)
    }
  `
  return new Promise((resolve, reject) => {
    commitMutation<MutationsForceConfigurationMutation>(EVRelayEnvironment, {
      mutation: mutation,
      variables: { id: documentId.toString() },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.forceConfiguration)
      }
    })
  })
}
