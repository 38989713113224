/**
 * @generated SignedSource<<50a0a9bd6666a5e7e0511e1766b99171>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsUpdateDocumentMutation$variables = {
  id: number;
  json: any;
};
export type MutationsUpdateDocumentMutation$data = {
  readonly updateDocument: {
    readonly _id: number;
    readonly referencingDocuments: ReadonlyArray<{
      readonly _id: number;
    }>;
    readonly referencingSchemas: ReadonlyArray<{
      readonly _id: number;
    }>;
  };
};
export type MutationsUpdateDocumentMutation = {
  response: MutationsUpdateDocumentMutation$data;
  variables: MutationsUpdateDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "json"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "json",
        "variableName": "json"
      }
    ],
    "concreteType": "DocumentVersion",
    "kind": "LinkedField",
    "name": "updateDocument",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Schema",
        "kind": "LinkedField",
        "name": "referencingSchemas",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "referencingDocuments",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsUpdateDocumentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsUpdateDocumentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c2c82c8db2819cfb5f58245717ebb399",
    "id": null,
    "metadata": {},
    "name": "MutationsUpdateDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsUpdateDocumentMutation(\n  $id: Int!\n  $json: JsonScalar!\n) {\n  updateDocument(documentId: $id, json: $json) {\n    _id\n    referencingSchemas {\n      _id\n    }\n    referencingDocuments {\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a3be5292676851e0be5438dc0998365";

export default node;
