import React from 'react'

import { UseNavigateWithParam } from 'src/Modules/Common/Hooks/UseNavigateWithParam'
import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { CloneButton } from 'src/Modules/Home/Components/NodeOptionButtons/CloneButton/CloneButton'

/**
 * Clone document button.
 * @param props The document id.
 * @returns A button to clone the document.
 */
export function CloneDocumentButton(props: {
  documentId: number
  className?: string
}) {
  const navigate = UseNavigateWithParam()
  const route = UseRoute()

  return (
    <CloneButton
      className={props.className}
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/${route}/document/${props.documentId}/clone`)
      }}
    />
  )
}
