/**
 * @generated SignedSource<<ad1bee3c67ec1f2593e8bf5f91c5c19c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesSchemaQuery$variables = {
  at?: string | null;
  schemaId: number;
};
export type QueriesSchemaQuery$data = {
  readonly schemaVersions: ReadonlyArray<{
    readonly json: any;
  } | null>;
};
export type QueriesSchemaQuery = {
  response: QueriesSchemaQuery$data;
  variables: QueriesSchemaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "at",
        "variableName": "at"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "schemaIds.0",
            "variableName": "schemaId"
          }
        ],
        "kind": "ListValue",
        "name": "schemaIds"
      }
    ],
    "concreteType": "SchemaVersion",
    "kind": "LinkedField",
    "name": "schemaVersions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "json",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSchemaQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesSchemaQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b88a409cb99d93c35cf119a799df1c9f",
    "id": null,
    "metadata": {},
    "name": "QueriesSchemaQuery",
    "operationKind": "query",
    "text": "query QueriesSchemaQuery(\n  $schemaId: Int!\n  $at: DateTime\n) {\n  schemaVersions(schemaIds: [$schemaId], at: $at) {\n    json\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ca098bca0f9f3018885b60bf7b7292e";

export default node;
