import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import React from 'react'

/**
 * from https://ant.design/components/upload/
 * @param props Dragger props.
 * @returns a dragger component
 */
export default function CustomDragger(
  props: React.ComponentProps<typeof Dragger>
) {
  return (
    <Dragger {...props}>
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text'>
        Click or drag file to this area to upload
      </p>
    </Dragger>
  )
}
