/**
 * @generated SignedSource<<1fc9633b5fe5f7d5fc6e9dac5d1753ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesValidateQuery$variables = {
  jsons: ReadonlyArray<any>;
  schemaId: number;
};
export type QueriesValidateQuery$data = {
  readonly validateDocuments: ReadonlyArray<ReadonlyArray<string>>;
};
export type QueriesValidateQuery = {
  response: QueriesValidateQuery$data;
  variables: QueriesValidateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jsons"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jsons",
        "variableName": "jsons"
      },
      {
        "kind": "Variable",
        "name": "schemaId",
        "variableName": "schemaId"
      }
    ],
    "kind": "ScalarField",
    "name": "validateDocuments",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesValidateQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesValidateQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "886671eacd8ef996366540cf3806d438",
    "id": null,
    "metadata": {},
    "name": "QueriesValidateQuery",
    "operationKind": "query",
    "text": "query QueriesValidateQuery(\n  $schemaId: Int!\n  $jsons: [JsonScalar!]!\n) {\n  validateDocuments(schemaId: $schemaId, jsons: $jsons)\n}\n"
  }
};
})();

(node as any).hash = "0f9c2cc58283669fdac5c2a27fae0cfd";

export default node;
