/**
 * @generated SignedSource<<0f0cb8861182499074674dbbea7f84b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentVersionsWithReferencesQuery$variables = {
  at?: string | null;
  documentIds?: ReadonlyArray<number> | null;
  orderBy?: ReadonlyArray<string> | null;
  schemaIds?: ReadonlyArray<number> | null;
};
export type QueriesDocumentVersionsWithReferencesQuery$data = {
  readonly documentVersions: ReadonlyArray<{
    readonly document: {
      readonly _id: number;
      readonly referencedByDocumentVersions: ReadonlyArray<{
        readonly document: {
          readonly _id: number;
        };
      }>;
      readonly schema: {
        readonly _id: number;
      };
      readonly versionAt: {
        readonly _id: number;
        readonly referencingDocuments: ReadonlyArray<{
          readonly _id: number;
        }>;
        readonly referencingSchemas: ReadonlyArray<{
          readonly _id: number;
        }>;
      } | null;
    };
  } | null>;
};
export type QueriesDocumentVersionsWithReferencesQuery = {
  response: QueriesDocumentVersionsWithReferencesQuery$data;
  variables: QueriesDocumentVersionsWithReferencesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaIds"
},
v4 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "at"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  (v4/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": [
      (v4/*: any*/),
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "documentIds"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "schemaIds",
        "variableName": "schemaIds"
      }
    ],
    "concreteType": "DocumentVersion",
    "kind": "LinkedField",
    "name": "documentVersions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "schema",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "DocumentVersion",
            "kind": "LinkedField",
            "name": "referencedByDocumentVersions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "DocumentVersion",
            "kind": "LinkedField",
            "name": "versionAt",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schema",
                "kind": "LinkedField",
                "name": "referencingSchemas",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "referencingDocuments",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentVersionsWithReferencesQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentVersionsWithReferencesQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "8d6b21a0ec7011c86ceb59e29087ee1f",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentVersionsWithReferencesQuery",
    "operationKind": "query",
    "text": "query QueriesDocumentVersionsWithReferencesQuery(\n  $documentIds: [Int!]\n  $schemaIds: [Int!]\n  $at: DateTime\n  $orderBy: [String!]\n) {\n  documentVersions(documentIds: $documentIds, schemaIds: $schemaIds, at: $at, orderBy: $orderBy) {\n    document {\n      _id\n      schema {\n        _id\n      }\n      referencedByDocumentVersions(at: $at) {\n        document {\n          _id\n        }\n      }\n      versionAt(at: $at) {\n        _id\n        referencingSchemas {\n          _id\n        }\n        referencingDocuments {\n          _id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c51cd9a56102d9dcc7ba03c732d8670";

export default node;
