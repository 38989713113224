/**
 * @generated SignedSource<<856f9e763b2db8d6e1ca305dd190438f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetSchemaKvpsQuery$variables = {
  ids?: ReadonlyArray<number> | null;
};
export type QueriesGetSchemaKvpsQuery$data = {
  readonly schemaKVPs: ReadonlyArray<{
    readonly key: string;
    readonly schemaId: number;
    readonly value: string;
  }>;
};
export type QueriesGetSchemaKvpsQuery = {
  response: QueriesGetSchemaKvpsQuery$data;
  variables: QueriesGetSchemaKvpsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "schemaIds",
        "variableName": "ids"
      }
    ],
    "concreteType": "SchemaKvp",
    "kind": "LinkedField",
    "name": "schemaKVPs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schemaId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetSchemaKvpsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetSchemaKvpsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f3c2f64b4c81726993a44451951c4fd7",
    "id": null,
    "metadata": {},
    "name": "QueriesGetSchemaKvpsQuery",
    "operationKind": "query",
    "text": "query QueriesGetSchemaKvpsQuery(\n  $ids: [Int!]\n) {\n  schemaKVPs(schemaIds: $ids) {\n    schemaId\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "32698974e42b3166f18a98ccc422c0fc";

export default node;
