import React from 'react'

import { CenteringDiv } from 'src/Modules/Utilities/Components/Centering/CenteringDiv'
import CustomSpinner from 'src/Modules/Utilities/Components/CustomSpinner'

/** Custom centered spinner icon */
export default function CenteredCustomSpinner() {
  return (
    <CenteringDiv>
      <CustomSpinner />
    </CenteringDiv>
  )
}
