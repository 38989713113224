import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { ComponentProps } from 'react'

/**
 * Button that can be used to clone a schema or document
 * @param props props of the icon
 * @returns A button with a delete icon
 */
export function CloneButton(props: ComponentProps<typeof CopyOutlined>) {
  return (
    <Tooltip title='Clone'>
      <CopyOutlined {...props} />
    </Tooltip>
  )
}
