/**
 * @generated SignedSource<<8be94dc4a17eba87fc9c218ef8bc182f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsSchemaPermissionsMutation$variables = {
  override?: boolean | null;
  read?: ReadonlyArray<string> | null;
  schemaId: number;
  write?: ReadonlyArray<string> | null;
};
export type MutationsSchemaPermissionsMutation$data = {
  readonly addSchemaAccess: {
    readonly _id: number;
  };
};
export type MutationsSchemaPermissionsMutation = {
  response: MutationsSchemaPermissionsMutation$data;
  variables: MutationsSchemaPermissionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "override"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "read"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "write"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "override",
        "variableName": "override"
      },
      {
        "kind": "Variable",
        "name": "read",
        "variableName": "read"
      },
      {
        "kind": "Variable",
        "name": "schemaId",
        "variableName": "schemaId"
      },
      {
        "kind": "Variable",
        "name": "write",
        "variableName": "write"
      }
    ],
    "concreteType": "Schema",
    "kind": "LinkedField",
    "name": "addSchemaAccess",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsSchemaPermissionsMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MutationsSchemaPermissionsMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f39a8c1fbf4bb3f52ff4e556c4ee9134",
    "id": null,
    "metadata": {},
    "name": "MutationsSchemaPermissionsMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsSchemaPermissionsMutation(\n  $schemaId: Int!\n  $read: [String!]\n  $write: [String!]\n  $override: Boolean\n) {\n  addSchemaAccess(schemaId: $schemaId, read: $read, write: $write, override: $override) {\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "35d91b44c2ef73f29dda620ccb6af37d";

export default node;
