import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import NotFound from 'src/Modules/Common/Components/NotFound'
import { UseNavigateWithParam } from 'src/Modules/Common/Hooks/UseNavigateWithParam'
import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { SchemaEditor } from 'src/Modules/DevPage/Components/SchemaEditor'
import {
  DocumentCloner,
  DocumentCreator
} from 'src/Modules/Home/Containers/DocumentCreator'
import { DocumentEditor } from 'src/Modules/Home/Containers/DocumentEditor'

/**
 * Hook to retrieve params and return the right content
 */
export function ParamContent() {
  const { type, id, action } = useParams()

  const navigate = UseNavigateWithParam()
  const route = UseRoute()

  const idNumber = id ? parseInt(id) : undefined
  const content = useMemo(() => {
    if (type && idNumber)
      switch (type) {
        case 'schema':
          switch (action) {
            case undefined:
              return (
                <SchemaEditor
                  key={idNumber}
                  schemaId={idNumber}
                  onSucceed={(id) => navigate(`/development/schema/${id}`)}
                />
              )
            case 'create':
              return (
                <DocumentCreator
                  key={idNumber}
                  schemaId={idNumber}
                  onSucceed={(id) => navigate(`/${route}/document/${id}`)}
                />
              )
          }
          break
        case 'document':
          switch (action) {
            case undefined:
              return (
                <DocumentEditor
                  key={idNumber}
                  documentId={idNumber}
                  onSucceed={(id) => navigate(`/${route}/document/${id}`)}
                />
              )
            case 'clone':
              return (
                <DocumentCloner
                  key={idNumber}
                  documentId={idNumber}
                  onSucceed={(id) => navigate(`/${route}/document/${id}`)}
                />
              )
          }
          break
      }
    else return <div />

    return <NotFound />
  }, [action, idNumber, navigate, route, type])

  return content
}
