import { getUiOptions, getWidget } from '@rjsf/core/lib/utils'
// Not allowed to ts-ignore, but in this case only option
// eslint-disable-next-line
// @ts-ignore See https://github.com/webpack/webpack/issues/7378 for suspected reason this gives an error
import { isValid } from '@rjsf/core/lib/validate'
import React, { useEffect, useState } from 'react'

import {
  GetDocumentVersion,
  GetDocumentWithSchema
} from 'src/Modules/Graphql/DocumentManager/Queries'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'

/**
 * Override for the multischema field that handles anyOf and oneOf fields
 * @param props Field props
 * @returns The multi schema fields
 */
export function MultiSchemaField(props: any) {
  const [selectedOption, setSelectedOption] = useState<number>()

  useEffect(() => {
    /** Wrapper for an async function in useEffect */
    async function asyncWrapper() {
      setSelectedOption(
        await GetMatchingOption(props.options, props.registry, props.formData)
      )
    }

    if (selectedOption === undefined) asyncWrapper()
  }, [
    props.registry,
    props.formData,
    props.options,
    setSelectedOption,
    selectedOption
  ])

  if (selectedOption === undefined) return <CenteredCustomSpinner />

  const SchemaField = props.registry.fields.SchemaField
  const { widget = 'select', ...uiOptions } = getUiOptions(props.uiSchema)
  const Widget = getWidget({ type: 'number' }, widget, props.registry.widgets)

  const option = props.options[selectedOption] || null
  let optionSchema

  if (option)
    // If the subschema doesn't declare a type, infer the type from the
    // parent schema
    optionSchema = option.type
      ? option
      : Object.assign({}, option, { type: props.baseType })

  const enumOptions = props.options.map((option: any, index: number) => ({
    label: option.title || `Option ${index + 1}`,
    value: index
  }))

  // Mostly copied from rjsf
  return (
    <div className='panel panel-default panel-body'>
      <div className='form-group'>
        <Widget
          id={`${props.idSchema.$id}${
            props.schema.oneOf ? '__oneof_select' : '__anyof_select'
          }`}
          schema={{ type: 'number', default: 0 }}
          onChange={onOptionChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          value={selectedOption}
          options={{ enumOptions }}
          {...uiOptions}
        />
      </div>

      {option !== null && (
        <SchemaField
          schema={optionSchema}
          uiSchema={props.uiSchema}
          errorSchema={props.errorSchema}
          idSchema={props.idSchema}
          idPrefix={props.idPrefix}
          formData={props.formData}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          registry={props.registry}
          disabled={props.disabled}
        />
      )}
    </div>
  )

  /**
   * Function called when the option select values changes
   * @param option The new selected option
   */
  function onOptionChange(option: string) {
    // Reset the value, so the option starts from scratch
    props.onChange(undefined)

    setSelectedOption(parseInt(option))
  }
}

/**
 * Get the option of which the schema matches agains the given data.
 * @returns The matching option index
 */
async function GetMatchingOption(
  options: any,
  registry: any,
  formData: any
): Promise<number> {
  // Most of this is copied from rjsf
  for (let i = 0; i < options.length; i++) {
    const option = options[i]
    // If the schema describes an object then we need to add slightly more
    // strict matching to the schema, because unless the schema uses the
    // "requires" keyword, an object will match the schema as long as it
    // doesn't have matching keys with a conflicting type. To do this we use an
    // "anyOf" with an array of requires. This augmentation expresses that the
    // schema should match if any of the keys in the schema are present on the
    // object and pass validation.
    if (option.properties) {
      // Create an "anyOf" schema that requires at least one of the keys in the
      // "properties" object
      const requiresAnyOf = {
        anyOf: Object.keys(option.properties).map((key) => ({
          required: [key]
        }))
      }

      let augmentedSchema

      // If the "anyOf" keyword already exists, wrap the augmentation in an "allOf"
      if (option.anyOf) {
        // Create a shallow clone of the option
        const { ...shallowClone } = option

        if (!shallowClone.allOf) shallowClone.allOf = []
        // If "allOf" already exists, shallow clone the array
        else shallowClone.allOf = shallowClone.allOf.slice()

        shallowClone.allOf.push(requiresAnyOf)

        augmentedSchema = shallowClone
      } else augmentedSchema = Object.assign({}, option, requiresAnyOf)

      // Remove the "required" field as it's likely that not all fields have
      // been filled in yet, which will mean that the schema is not valid
      delete augmentedSchema.required

      if (await CustomIsValid(augmentedSchema, registry.rootSchema, formData))
        return i
    } else if (await CustomIsValid(option, registry.rootSchema, formData))
      return i
  }
  // If no matching schema can be found, simply choose the first option
  return 0
}

/**
 * Check if the form data is valid against the given schema
 * @param schema Schema to validate against
 * @returns Whether the data is valid against the schema
 */
async function CustomIsValid(schema: any, rootSchema: any, formData: any) {
  // Custom validation needs to be done when the number is actually a document reference
  if (schema.isDocumentRef) {
    // But if no data is set, it can't possibly validate
    if (
      !formData ||
      (Object.keys(formData).length === 0 && formData.constructor === Object)
    )
      return false

    // If the document does not need to implement a schema, then the only needed check is to see if the document exists
    if (schema.documentImplementsSchema !== undefined) {
      const documentWithSchema = await GetDocumentWithSchema(formData)
      return (
        documentWithSchema &&
        documentWithSchema.document.schema._id ===
          schema.documentImplementsSchema
      )
    } // If it does need to validate with the schema, then it needs to be checked if the document exists and implements the given schema
    else {
      const document = await GetDocumentVersion(formData)
      return document !== undefined && document !== null
    }
  }
  // For other fields, use the default validation methods
  return isValid(schema, formData, rootSchema)
}
