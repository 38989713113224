import styled from '@emotion/styled'
import { Button } from 'antd'
import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import React, { useMemo, useRef } from 'react'
import { Circle, MapContainer, Popup, TileLayer } from 'react-leaflet'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { GetPaginatedCoordinates } from 'src/Modules/Graphql/LoggingDatabase/Queries'
import { QueriesPaginatedCoordinatesQuery$data } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedCoordinatesQuery.graphql'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import UseOneOrMany from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/UseIds'
import { ColorGenerator } from 'src/Modules/Utilities/Colors'

type coordinate = NonNullable<
  NonNullable<
    QueriesPaginatedCoordinatesQuery$data['paginatedCoordinates']
  >['items']
>[0]

/**
 * A page to show coordinates
 * @param props props
 * @returns a component
 */
export default function CoordinatePage(props: {
  documentIds: number | number[]
}) {
  const route = UseRoute()
  const documentIds = UseOneOrMany(props.documentIds)
  const queryClient = useQueryClient()

  type PageInfo =
    | {
        endCursor: string | null
        hasNextPage: boolean
      }
    | undefined

  /**
   * Function to fetch the next coordinates
   * @param param page params passed by infinite query - in our case the end cursor of the last page
   * @returns Page info containing the end cursor/has next page and the coordinates
   */
  async function fetchCoordinates({
    pageParam = undefined
  }): Promise<[PageInfo, readonly coordinate[]]> {
    const result = (await GetPaginatedCoordinates(documentIds, 20, pageParam))
      ?.paginatedCoordinates
    return [result?.pageInfo, result?.items ?? []]
  }

  const { data, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery(
    ['coordinates', documentIds],
    fetchCoordinates,
    {
      getNextPageParam: (lastPage, _) =>
        lastPage[0]?.hasNextPage ? lastPage[0].endCursor : undefined,
      staleTime: Infinity
    }
  )

  const colorGenerator = useRef(new ColorGenerator())

  const dots = useMemo(() => {
    const now = Date.now().valueOf()
    return data?.pages
      .flatMap(([, page]) => page)
      .map((coordinate) => (
        <Circle
          key={coordinate._id}
          center={[coordinate.latitude, coordinate.longitude]}
          pathOptions={{
            color: colorGenerator.current.GetColor(coordinate.documentId),
            //Scale by a week, for fade.
            opacity:
              ((now - new Date(coordinate.dateTime).valueOf()) /
                (1000 * 60 * 60 * 24 * 7)) *
                0.9 +
              0.1
          }}
        >
          <Popup>
            <Link to={`/${route}/document/${coordinate.documentId}`}>
              {`document: ${coordinate.documentId} at: ${moment(
                coordinate.dateTime
              ).format('YYYY/MM/DD HH:mm:ss')}`}
            </Link>
          </Popup>
        </Circle>
      ))
  }, [data?.pages, route])

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            type='primary'
            onClick={async () => await fetchNextPage()}
            loading={isFetching}
            disabled={!hasNextPage}
          >
            Load More
          </Button>

          <Button
            onClick={() => {
              queryClient.removeQueries(['coordinates', documentIds])
              fetchNextPage()
            }}
            loading={isFetching}
          >
            Refresh
          </Button>
        </>
      }
    >
      <MapContainer
        // The netherlands
        //@ts-expect-error incorrect types from package
        center={[52.128506917210075, 5.418188998670511]}
        zoom={7}
        style={{ height: '100%' }}
      >
        <DarkModeTileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        {dots}
      </MapContainer>
    </TabContentWrapper>
  )
}

const DarkModeTileLayer = styled(TileLayer)`
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3)
    brightness(0.7);
`
