import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import JsonEditor from 'src/Modules/Common/Components/JsonEditor'
import { SetUiSchema } from 'src/Modules/Graphql/MetaDataManager/Mutations'
import { GetUiSchema } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * A component to edit the schema's UISchema
 * @param props props
 * @returns a component
 */
export default function UiSchemaEditor(props: { schemaId: number }) {
  const [json, setJson] = useState<string>()
  const [initialJson, setInitialJson] = useState<any>()
  const [uiSchemaExists, setUiSchemaExists] = useState<boolean>(false)

  useEffect(() => {
    const asyncWrapper = async function () {
      const uiSchema = await GetUiSchema(props.schemaId)
      if (uiSchema) setUiSchemaExists(true)
      // If no uiSchema is found, use a default empty string.
      setJson(JSON.stringify(uiSchema) ?? '')
      // Initial json must be an object instead of string, but is not changed later
      setInitialJson(uiSchema)
    }

    // Only run on initialization
    if (json === undefined) asyncWrapper()
  }, [props.schemaId, json])

  if (json === undefined) return <CenteredCustomSpinner />

  return (
    <TabContentWrapper
      bottom={
        uiSchemaExists ? (
          <Button type='primary' onClick={() => UpdateThisUiSchema()}>
            Update uiSchema
          </Button>
        ) : (
          <Button type='primary' onClick={() => AddThisUiSchema()}>
            Add uiSchema
          </Button>
        )
      }
    >
      <JsonEditor
        options={{
          mode: 'code',
          modes: ['view', 'code', 'preview'],
          onChangeText: (json) => setJson(json)
        }}
        json={initialJson}
      />
    </TabContentWrapper>
  )

  /** Add a new UISchema */
  async function AddThisUiSchema() {
    if (!ValidateJson()) return
    try {
      await SetUiSchema(props.schemaId, json as string)
    } catch (e: any) {
      NotificationError('Error when adding uischema', e.error, e)
    }
    setUiSchemaExists(true)

    NotificationSuccess('Success', 'Successfully added uischema')
  }

  /** Update the current uischema */
  async function UpdateThisUiSchema() {
    if (!ValidateJson()) return
    try {
      await SetUiSchema(props.schemaId, json as string)
    } catch (e: any) {
      NotificationError('Error when adding uischema', e.error, e)
    }

    NotificationSuccess('Success', 'Successfully updated uischema')
  }

  /** Parse the json currently in state, catch error if it is invalid */
  function ValidateJson(): boolean {
    try {
      JSON.parse(json as string)
      return true
    } catch (e: any) {
      NotificationError('Bad json', 'Please provide valid json.', e)
      return false
    }
  }
}
