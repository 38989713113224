/**
 * @generated SignedSource<<8dead6c2079da2285b44ae6a161af083>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsRemoveDocumentMutation$variables = {
  documentId: number;
};
export type MutationsRemoveDocumentMutation$data = {
  readonly removeDocument: ReadonlyArray<number> | null;
};
export type MutationsRemoveDocumentMutation = {
  response: MutationsRemoveDocumentMutation$data;
  variables: MutationsRemoveDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "kind": "ScalarField",
    "name": "removeDocument",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsRemoveDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsRemoveDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d832cbfb3cf2afe9067366d8cc88fc62",
    "id": null,
    "metadata": {},
    "name": "MutationsRemoveDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsRemoveDocumentMutation(\n  $documentId: Int!\n) {\n  removeDocument(documentId: $documentId)\n}\n"
  }
};
})();

(node as any).hash = "3efb53e4de35dfc340e0a1d297cc2f4a";

export default node;
