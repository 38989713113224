/**
 * @generated SignedSource<<52eb0cb30ff252fac1974793f639a367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesDocumentQuery$variables = {
  at?: string | null;
  ids?: ReadonlyArray<number> | null;
};
export type QueriesDocumentQuery$data = {
  readonly documents: ReadonlyArray<{
    readonly versionAt: {
      readonly json: any;
    } | null;
  }>;
};
export type QueriesDocumentQuery = {
  response: QueriesDocumentQuery$data;
  variables: QueriesDocumentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "at",
            "variableName": "at"
          }
        ],
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "versionAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "json",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesDocumentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesDocumentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f20f0814aa731eecd8a43d5e697f3a66",
    "id": null,
    "metadata": {},
    "name": "QueriesDocumentQuery",
    "operationKind": "query",
    "text": "query QueriesDocumentQuery(\n  $ids: [Int!]\n  $at: DateTime\n) {\n  documents(ids: $ids) {\n    versionAt(at: $at) {\n      json\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9626f7a2757cc5087eaf2cc5d33dc58";

export default node;
