import { useLocation } from 'react-router-dom'

/**
 * Return the route name from the used path.
 * @returns The current route.
 */
export function UseRoute() {
  const location = useLocation()
  const route = location.pathname.split('/')[1]
  return route === '' ? 'home' : route
}
