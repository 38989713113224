import { Popover } from 'antd'
import React, { Suspense } from 'react'

import { UpdateSchema } from 'src/Modules/Graphql/DocumentManager/Mutations'
import TopContentWrapper from 'src/Modules/Home/Components/ContentWrappers/TopContentWrapper'
import UiSchemaEditor from 'src/Modules/Home/Components/Editors/UiSchemaEditor'
import { DocumentImporter } from 'src/Modules/Home/Components/Import/Importer'
import { RenameSchemaButton } from 'src/Modules/Home/Components/NodeOptionButtons/RenameButton/RenameSchemaButton'
import {
  TabPaneWrapper,
  TabsWrapper
} from 'src/Modules/Home/Components/TabsWrapper'
import { StyledFormOutlined } from 'src/Modules/Home/Components/Title/TitleIcons/StyledFomOutlined'
import { EditorTitle } from 'src/Modules/Home/Components/Title/TitleText/EditorTitle'
import CoordinatePage from 'src/Modules/Home/Containers/Content/Tabs/CoordinatePage'
import { DocumentCards } from 'src/Modules/Home/Containers/Content/Tabs/DocumentCards'
import { SchemaCodeEditor } from 'src/Modules/Home/Containers/Content/Tabs/Editors/SchemaCodeEditor'
import { KvpEditor } from 'src/Modules/Home/Containers/Content/Tabs/KvpEditor'
import MacAddressPage from 'src/Modules/Home/Containers/Content/Tabs/MacAddressPage'
import Permissions from 'src/Modules/Home/Containers/Content/Tabs/Permissions'
import SchemaReferencesPage from 'src/Modules/Home/Containers/Content/Tabs/ReferencesPage/SchemaReferences'
import StatusPage from 'src/Modules/Home/Containers/Content/Tabs/StatusPage'
import useIntegrations from 'src/Modules/Home/Containers/Content/UseIntegrations'
import { invalidateSchemas, useSchema } from 'src/Modules/Home/Hooks/Schema'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'
import UseRole from 'src/Modules/Utilities/Authorization/UseRole'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/** Container that implements a JsonEditor and uploads the schema on submit */
export function SchemaEditor(props: {
  schemaId: number
  onSucceed: (schemaId: number) => void
}) {
  const isDeveloper = UseRole('Developer')

  const Integrations = useIntegrations(props.schemaId)

  const schemaResult = useSchema(props.schemaId, { suspense: true })
  const schemaNameResult = useSchemaName(props.schemaId, { suspense: true })

  if (!schemaResult.isSuccess || !schemaNameResult.isSuccess) return null

  const schema = schemaResult.data
  const name = schemaNameResult.data?.value

  return (
    <TopContentWrapper
      top={
        <div>
          <StyledFormOutlined />
          <Popover
            title={`Schema Id: ${schema.id}`}
            content={
              schema.versionId
                ? `Schema Version Id: ${schema.versionId}`
                : 'Unknown Schema Version'
            }
            trigger='hover'
          >
            <EditorTitle disabled={name === undefined}>
              {name ?? 'no name'}
            </EditorTitle>
          </Popover>
          <RenameSchemaButton
            key='Rename'
            schemaId={props.schemaId}
            name={name ?? undefined}
          />
        </div>
      }
    >
      {Integrations && (
        <TabsWrapper>
          <TabPaneWrapper tab='Documents' key='Documents'>
            <DocumentCards
              schemaId={props.schemaId}
              documentIds={schema.documents}
            />
          </TabPaneWrapper>

          {Integrations.hasLoggingStatus && schema.documents && (
            <TabPaneWrapper tab='Status' key='Status'>
              <StatusPage documentIds={schema.documents} />
            </TabPaneWrapper>
          )}
          {Integrations.hasLoggingCoordinates && schema.documents && (
            <TabPaneWrapper tab='Coordinates' key='Coordinates'>
              <CoordinatePage documentIds={schema.documents} />
            </TabPaneWrapper>
          )}
          {Integrations.hasLoggingMacAddresses && schema.documents && (
            <TabPaneWrapper tab='MacAddresses' key='MacAddresses'>
              <MacAddressPage documentIds={schema.documents} />
            </TabPaneWrapper>
          )}
          {Integrations.hasImport && (
            <TabPaneWrapper tab='Import' key='Import'>
              <DocumentImporter
                key={props.schemaId}
                submitText={`New ${name ?? 'document'}(s)`}
                schemaId={props.schemaId}
              />
            </TabPaneWrapper>
          )}
          {isDeveloper && (
            <>
              <TabPaneWrapper tab='Json Editor' key='Json Editor'>
                <SchemaCodeEditor
                  schemaId={props.schemaId}
                  onSubmit={(json) => UpdateThisSchema(json)}
                />
              </TabPaneWrapper>
              <TabPaneWrapper tab='UiSchema Editor' key='UiSchema Editor'>
                <UiSchemaEditor schemaId={props.schemaId} />
              </TabPaneWrapper>
              <TabPaneWrapper tab='Permissions' key='Permissions'>
                <Permissions schemaId={props.schemaId} />
              </TabPaneWrapper>
              <TabPaneWrapper tab='Metadata' key='Metadata'>
                <KvpEditor schemaId={props.schemaId} />
              </TabPaneWrapper>
              <TabPaneWrapper tab='References' key='References'>
                <Suspense fallback={<CenteredCustomSpinner />}>
                  <SchemaReferencesPage schemaId={props.schemaId} />
                </Suspense>
              </TabPaneWrapper>
            </>
          )}
        </TabsWrapper>
      )}
    </TopContentWrapper>
  )

  /**
   * Updates a schema at the document manager
   * @param json the json of the new schema version
   */
  async function UpdateThisSchema(json: any) {
    // Update the schema
    let newSchema
    try {
      newSchema = await UpdateSchema(props.schemaId as number, json)
    } catch (e: any) {
      NotificationError('Error on Schema Update', e.message, e)
      return
    }

    await invalidateSchemas([
      props.schemaId,
      ...(schema?.referencingSchemas ?? []),
      ...newSchema.referencingSchemas.map((schema) => schema._id)
    ])

    NotificationSuccess('Success', 'Successfully updated schema.')

    // On succesful update
    props.onSucceed(props.schemaId)
  }
}
