/**
 * @generated SignedSource<<a748d4a5acfb6843a3b24599e6110741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetDocumentKvpsQuery$variables = {
  ids?: ReadonlyArray<number> | null;
};
export type QueriesGetDocumentKvpsQuery$data = {
  readonly documentKVPs: ReadonlyArray<{
    readonly documentId: number;
    readonly key: string;
    readonly value: string;
  }>;
};
export type QueriesGetDocumentKvpsQuery = {
  response: QueriesGetDocumentKvpsQuery$data;
  variables: QueriesGetDocumentKvpsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "ids"
      }
    ],
    "concreteType": "DocumentKvp",
    "kind": "LinkedField",
    "name": "documentKVPs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetDocumentKvpsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetDocumentKvpsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1cfd2f04c8e89c724117e264d7da4afb",
    "id": null,
    "metadata": {},
    "name": "QueriesGetDocumentKvpsQuery",
    "operationKind": "query",
    "text": "query QueriesGetDocumentKvpsQuery(\n  $ids: [Int!]\n) {\n  documentKVPs(documentIds: $ids) {\n    documentId\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b8a103ae23aa045e89d563203187be9";

export default node;
