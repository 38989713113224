import styled from '@emotion/styled'
import React from 'react'

import { SchemaCard } from 'src/Modules/DevPage/Components/SchemaCard'
import { GetSchemas } from 'src/Modules/Graphql/DocumentManager/Queries'
import { CardsWrapper } from 'src/Modules/Home/Components/Card/CardWrapper'
import { UseCustomQuery } from 'src/Modules/Home/Hooks/QueryClient'

/**
 * Component containing a card for every schema in the document manager
 * @returns Multiple schema cards and an add button
 */
export function SchemaCards() {
  const { data: schemas } = UseCustomQuery('schemas', GetSchemas, {
    suspense: true
  })

  return (
    <PartialHeightDiv>
      <CardsWrapper>
        {
          // Render all schemas
          schemas!.map(({ _id: schemaId }) => {
            return <SchemaCard key={schemaId} schemaId={schemaId} />
          })
        }
      </CardsWrapper>
    </PartialHeightDiv>
  )
}

const PartialHeightDiv = styled.div`
  height: auto;
  overflow-y: auto;
  margin: 10px;
`
