import React from 'react'

import { StyledLink } from 'src/Modules/Common/Components/LinkWithParam'
import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { CardsWrapper } from 'src/Modules/Home/Components/Card/CardWrapper'
import { DocumentCard } from 'src/Modules/Home/Components/Card/DocumentCard'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'
import CenteredEmpty from 'src/Modules/Utilities/Components/Centering/CenteredEmpty'

/**
 * A page with multiple document cards.
 * @param props Document Id Props.
 * @returns A page with multiple document cards.
 */
export function DocumentCards(props: {
  schemaId: number
  documentIds: number[]
}) {
  const route = UseRoute()

  const { data: schemaName } = useSchemaName(props.schemaId)

  return (
    <TabContentWrapper
      bottom={
        <StyledLink to={`/${route}/schema/${props.schemaId}/create`}>
          Create new {schemaName?.value ?? 'document'}
        </StyledLink>
      }
    >
      {props.documentIds.length === 0 ? (
        <CenteredEmpty />
      ) : (
        <CardsWrapper>
          {props.documentIds?.map((id) => (
            <DocumentCard documentId={id} key={id} />
          ))}
        </CardsWrapper>
      )}
    </TabContentWrapper>
  )
}
