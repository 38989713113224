import styled from '@emotion/styled'
import { groupBy } from 'lodash'
import React, { useMemo } from 'react'

import { FullHeightDiv } from 'src/Modules/Common/Containers/FullHeightDiv'
import { CategoryTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/CategoryTreeNode'
import { TreeProjection } from 'src/Modules/Home/Components/Tree/TreeWithSelect'

/** Custom tree to show schemas and documents */
export function SchemaDocumentTree(props: {
  rootProjections: TreeProjection[]
}) {
  const CategoryNodes = useMemo(() => {
    const groupedProjections = groupBy(
      props.rootProjections,
      (proj) => proj.categoryName
    )

    return (
      <FullHeightDiv>
        {Object.keys(groupedProjections).map((proj) => (
          <CategoryTreeNode
            name={proj}
            key={proj}
            projections={groupedProjections[proj]}
            defaultOpen={Object.keys(groupedProjections).length === 1}
          />
        ))}
      </FullHeightDiv>
    )
  }, [props.rootProjections])

  return <StyledTree>{CategoryNodes}</StyledTree>
}

const StyledTree = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`
