/**
 * @generated SignedSource<<18a74fe4785bdb1652e06db0648e68f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesReferencesFromDocumentQuery$variables = {
  ids?: ReadonlyArray<number> | null;
  now?: string | null;
};
export type QueriesReferencesFromDocumentQuery$data = {
  readonly documents: ReadonlyArray<{
    readonly referencedByDocumentVersions: ReadonlyArray<{
      readonly document: {
        readonly _id: number;
        readonly schema: {
          readonly _id: number;
        };
      };
    }>;
  }>;
};
export type QueriesReferencesFromDocumentQuery = {
  response: QueriesReferencesFromDocumentQuery$data;
  variables: QueriesReferencesFromDocumentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "now"
  }
],
v1 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "now"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      (v1/*: any*/),
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "referencedByDocumentVersions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schema",
                "kind": "LinkedField",
                "name": "schema",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesReferencesFromDocumentQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesReferencesFromDocumentQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c0f50831bf11eeea9a6ff3a72a0dd4f7",
    "id": null,
    "metadata": {},
    "name": "QueriesReferencesFromDocumentQuery",
    "operationKind": "query",
    "text": "query QueriesReferencesFromDocumentQuery(\n  $ids: [Int!]\n  $now: DateTime\n) {\n  documents(ids: $ids, at: $now) {\n    referencedByDocumentVersions(at: $now) {\n      document {\n        _id\n        schema {\n          _id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3439022837e8e0e9394c8cf4e0086476";

export default node;
