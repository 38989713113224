import styled from '@emotion/styled'
import { Input, List } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { FullHeightDiv } from 'src/Modules/Common/Containers/FullHeightDiv'
import { GetActiveDocumentIds } from 'src/Modules/Graphql/DocumentManager/Queries'
import { GetDocumentsWithRegex } from 'src/Modules/Graphql/MetaDataManager/Queries'
import { DocumentTreeNode } from 'src/Modules/Home/Components/Tree/TreeNodes/DocumentTreeNode'
import { invalidateDocuments } from 'src/Modules/Home/Hooks/Document'
import { invalidateDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { searchParam } from 'src/Modules/Utilities/Environment'

/**
 * Search page that contains a list of search results for document names.
 * @param props Search query that was passed in input box or URL directly.
 * @returns Search list of results.
 */
export default function SidebarSearch() {
  // Get/set the search query in the URL
  const [searchParams, setSearchParams] = useSearchParams()

  const [documents, setDocuments] = useState<number[]>()

  /**
   * Searches for documents containing query in their name
   * @param query Query to search for in documents database
   */
  const search = useCallback(async (query: string | null) => {
    if (!query) return

    // Get ids of documents of which the name matches the query
    const documentIds = await GetDocumentsWithRegex(query)
    if (!documentIds) return

    // Filter only on the currently active documents
    const activeIds = await GetActiveDocumentIds(documentIds)
    if (!activeIds) return

    // Load documents and document names into context
    await invalidateDocuments(activeIds)
    await invalidateDocumentNames(activeIds)

    setDocuments(activeIds)
  }, [])

  useEffect(() => {
    search(searchParams.get(searchParam))
  }, [search, searchParams])

  return (
    <GridDiv>
      <Input.Search
        defaultValue={searchParams.get(searchParam) ?? ''}
        placeholder='Document name...'
        onSearch={(value) => {
          // Force search refresh, otherwise search is handled by useEffect
          if (searchParams.get(searchParam) === value) search(value)

          searchParams.set(searchParam, value)
          setSearchParams(searchParams)
        }}
        style={{
          width: '100%',
          padding: '4px 3px'
        }}
      />
      <List
        header={<strong>Results</strong>}
        style={{ cursor: 'pointer', overflowY: 'auto', margin: '5px' }}
        size='small'
        bordered
        dataSource={documents}
        renderItem={(documentId) => (
          <DocumentTreeNode documentId={documentId} />
        )}
      />
    </GridDiv>
  )
}

const GridDiv = styled(FullHeightDiv)`
  display: grid;
  grid-template-rows: auto 1fr;
`
