import { notification } from 'antd'

/**
 * Shows a success message on the page
 * @param message the title of the message
 * @param description A more detailed success description
 */
export function NotificationSuccess(message: string, description: string) {
  notification.success({ message, description, placement: 'bottomRight' })
}
