import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import styled from '@emotion/styled'
import { ErrorSchema, FieldProps } from '@rjsf/core'
import { Button, List } from 'antd'
import { JSONSchema7 } from 'json-schema'
import React, { useState } from 'react'

type keyedItem = {
  item: any
  key: number
}

/**
 * Custom field for arrays which adds custom styling and a duplicate function
 * @param props RJSF props
 * @returns The custom array
 */
export function CustomArray(props: FieldProps<any[]>) {
  // Set values in the state to keep data persistent
  let key = 0
  const [values, setValues] = useState<keyedItem[]>(
    props.formData?.map((x) => {
      return { item: x, key: key++ }
    }) ?? []
  )

  // Map values to react items
  let i = 0
  const children =
    values.length !== 0 ? (
      values.map(() => RenderItem(i++))
    ) : (
      <span className='text-secondary'>
        This list does not contain any items yet
      </span>
    )

  return (
    <>
      <h6>{props.name}</h6>
      <List>{children}</List>
      {!props.disabled && (
        <Button size='small' onClick={() => Add()}>
          + Add new item
        </Button>
      )}
    </>
  )

  /**
   * Render a single array items
   * @param index Index of the item in the current array of values
   * @returns The rendered item
   */
  function RenderItem(index: number) {
    const everyItemUiSchema = props.uiSchema.items
    const indexedItemUiSchema = props.uiSchema[index]
    const itemUiSchema =
      everyItemUiSchema || indexedItemUiSchema
        ? { ...everyItemUiSchema, ...indexedItemUiSchema }
        : undefined
    // Create rjsf props
    const itemProps: any = {
      schema: (props.schema.items as JSONSchema7) ?? {},
      // Apply the style for items on all items, and the style for the current item on the current item
      uiSchema: itemUiSchema,
      formData: values[index].item,
      formContext: props.formContext,
      errorSchema: props.errorSchema[index],
      idSchema: props.idSchema,
      // Todo: check wheter items are required or not
      required: props.required,
      onChange: onChangeForIndex(index),
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      registry: props.registry,
      disabled: props.disabled,
      readonly: props.readonly,
      autofocus: props.autofocus,
      rawErrors: props.rawErrors,
      showErrorList: false
    }

    return (
      <StyledListItem key={index}>
        {/* Create child schema field */}
        <itemProps.registry.fields.SchemaField
          {...itemProps}
          key={values[index].key}
        />

        {/* Control buttons */}
        <StyledButtonGroup disabled={props.disabled}>
          <Button onClick={() => Duplicate(index)}>
            <CopyOutlined />
          </Button>
          <Button onClick={() => Delete(index)}>
            <DeleteOutlined />
          </Button>
          <Button onClick={() => MoveDown(index - 1)}>
            <ArrowUpOutlined />
          </Button>
          <Button onClick={() => MoveDown(index)}>
            <ArrowDownOutlined />
          </Button>
        </StyledButtonGroup>
      </StyledListItem>
    )
  }

  /**
   * Handle a change in data for an item in the array
   * @param index Index of the item in the array that changed
   * @returns An onChange function that takes the new data and an error schema
   */
  function onChangeForIndex(index: number) {
    return (e: any, es?: ErrorSchema | undefined) => {
      values[index] = { item: e, key: values[index].key }
      setValues(values)
      props.onChange(values.map(ToItem))
    }
  }

  /**
   * Duplicate an item in the array and place it at the end
   * @param index Index of the item to duplicate
   */
  function Duplicate(index: number) {
    values.push({ item: values[index].item, key: key++ })
    setValues(values)
    props.onChange(values.map(ToItem))
  }

  /**
   * Delete an item in the array
   * @param index Index of the item to duplicate
   */
  function Delete(index: number) {
    values.splice(index, 1)
    setValues(values)
    props.onChange(values.map(ToItem))
  }

  /**
   * Move an item in the array down
   * @param index Index of the item to move down
   * @returns
   */
  function MoveDown(index: number) {
    if (index > values.length - 1 || index < 0) return
    const copy = values.splice(index, 1)
    values.splice(index + 1, 0, copy[0])
    setValues(values)
    props.onChange(values.map(ToItem))
  }

  /** Add an item to the array */
  function Add() {
    values.push({ item: null, key: key++ })
    setValues(values)
    props.onChange(values.map(ToItem))
  }

  /**
   * Extract the item from a keyedItem
   * @param x The keyedItem
   * @returns The item
   */
  function ToItem(x: keyedItem): any {
    return x.item
  }
}

// Styling
const StyledListItem = styled(List.Item)`
  display: flex;
  padding: 15px 0px 0px 0px;
  margin-left: 10;
`

const StyledButtonGroup = styled(Button.Group)<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  padding-left: 15px;
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
  margin-bottom: 15px;
`
