/**
 * @generated SignedSource<<df85572be4b249d8f9854217cd6314f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsAddDocumentMutation$variables = {
  json: any;
  schemaId: number;
};
export type MutationsAddDocumentMutation$data = {
  readonly addDocument: {
    readonly _id: number;
    readonly versionAt: {
      readonly referencingDocuments: ReadonlyArray<{
        readonly _id: number;
      }>;
      readonly referencingSchemas: ReadonlyArray<{
        readonly _id: number;
      }>;
    } | null;
  };
};
export type MutationsAddDocumentMutation = {
  response: MutationsAddDocumentMutation$data;
  variables: MutationsAddDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "json"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schemaId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "json",
        "variableName": "json"
      },
      {
        "kind": "Variable",
        "name": "schemaId",
        "variableName": "schemaId"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "addDocument",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "versionAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schema",
            "kind": "LinkedField",
            "name": "referencingSchemas",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "referencingDocuments",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsAddDocumentMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MutationsAddDocumentMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9c3b6ebd005262a20e488aae0726e7ed",
    "id": null,
    "metadata": {},
    "name": "MutationsAddDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsAddDocumentMutation(\n  $schemaId: Int!\n  $json: JsonScalar!\n) {\n  addDocument(schemaId: $schemaId, json: $json) {\n    _id\n    versionAt {\n      referencingSchemas {\n        _id\n      }\n      referencingDocuments {\n        _id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "918c9f4f169e35d4ab17c6261a36da95";

export default node;
