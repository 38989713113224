import React from 'react'

import {
  DeleteDocumentKVP,
  SetDocumentKVP
} from 'src/Modules/Graphql/MetaDataManager/Mutations'
import {
  BigRenameButton,
  RenameButtonProps
} from 'src/Modules/Home/Components/NodeOptionButtons/RenameButton/RenameButton'
import { invalidateDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { executeSafelyAsync } from 'src/Modules/Utilities/ErrorHandler'

/**
 * Document rename button
 * @param props props
 * @returns A button to rename a document
 */
export function RenameDocumentButton(
  props: {
    documentId: number
  } & RenameButtonProps
) {
  return <BigRenameButton {...props} onOk={(value) => handleOk(value)} />

  /** Method called when the rename modal is submitted */
  async function handleOk(value?: string) {
    await executeSafelyAsync(async () => {
      if (value) await SetDocumentKVP(props.documentId, 'name', value)
      else if (props.name) await DeleteDocumentKVP(props.documentId, 'name')
      else return

      await invalidateDocumentNames([props.documentId])
      props.onSuccess?.(value)
    })
  }
}
