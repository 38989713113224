import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import { useQuery } from 'react-query'

import { AddDocument } from 'src/Modules/Graphql/DocumentManager/Mutations'
import { SetDocumentKVP } from 'src/Modules/Graphql/MetaDataManager/Mutations'
import TopContentWrapper from 'src/Modules/Home/Components/ContentWrappers/TopContentWrapper'
import { NameInput } from 'src/Modules/Home/Components/NameInput'
import {
  TabPaneWrapper,
  TabsWrapper
} from 'src/Modules/Home/Components/TabsWrapper'
import { StyledFileOutlined } from 'src/Modules/Home/Components/Title/TitleIcons/StyledFileOutlined'
import { CreatorTitle } from 'src/Modules/Home/Components/Title/TitleText/CreatorTitle'
import {
  ExistingDocumentCodeEditor,
  NewDocumentCodeEditor
} from 'src/Modules/Home/Containers/Content/Tabs/Editors/DocumentCodeEditor'
import {
  invalidateDocuments,
  useDocument
} from 'src/Modules/Home/Hooks/Document'
import { invalidateDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { invalidateSchemas } from 'src/Modules/Home/Hooks/Schema'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'
import {
  AutoNameInputs,
  GenerateAutoName,
  GetAutoName
} from 'src/Modules/Utilities/AutoName'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'
import { DocumentName } from 'src/Modules/Utilities/DocumentName'
import { NotificationError } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/** Container that contains a title and React Json Schema Form */
export function DocumentCreator(props: {
  schemaId: number
  onSucceed: (documentId: number) => void
}) {
  return (
    <DocumentCreatorOrCloner
      schemaId={props.schemaId}
      editor={(onSubmit) => (
        <NewDocumentCodeEditor schemaId={props.schemaId} onSubmit={onSubmit} />
      )}
      onSucceed={props.onSucceed}
    />
  )
}

/** Container that contains a title and React Json Schema Form */
export function DocumentCloner(props: {
  documentId: number
  onSucceed: (documentId: number) => void
}) {
  const { data: document } = useDocument(props.documentId, { suspense: true })

  return (
    <DocumentCreatorOrCloner
      schemaId={document!.schemaId}
      editor={(onSubmit) => (
        <ExistingDocumentCodeEditor
          documentId={props.documentId}
          onSubmit={onSubmit}
        />
      )}
      onSucceed={props.onSucceed}
    />
  )
}

/** Container that contains a title and React Json Schema Form */
function DocumentCreatorOrCloner(props: {
  schemaId: number
  editor: (onSubmit: (schema: any, formData: any) => Promise<void>) => ReactNode
  onSucceed: (documentId: number) => void
}) {
  const [name, setName] = useState<string>()
  const [autoNameInputs, setAutoNameInputs] = useState<string[]>([])
  const { data: schemaName } = useSchemaName(props.schemaId)
  const { data: autoName } = useQuery(
    ['autoName', props.schemaId],
    async () => (await GetAutoName(props.schemaId)) ?? null,
    { suspense: true }
  )

  if (autoName === undefined) return <CenteredCustomSpinner />

  return (
    <TopContentWrapper
      top={
        <div>
          <StyledFileOutlined />
          <CreatorTitle level={2}>
            New {schemaName?.value ?? 'document'}
          </CreatorTitle>
        </div>
      }
    >
      <ContentFreeWrapper>
        <div>
          <NameInput
            onNameChange={(name) => setName(name)}
            value={autoName ? 'Name automatically generated' : name}
            disabled={autoName !== null}
          />
          {autoName && (
            <AutoNameInputs
              autoName={autoName}
              onInputChange={setAutoNameInputs}
              inputs={autoNameInputs}
            />
          )}
        </div>
        <TabsWrapper>
          <TabPaneWrapper tab='Configuration' key='Configuration'>
            {props.editor((schema, formData) =>
              AddThisDocument(schema, formData)
            )}
          </TabPaneWrapper>
        </TabsWrapper>
      </ContentFreeWrapper>
    </TopContentWrapper>
  )

  /**
   * Add the document in this editor to the document manager, including name, then reload the page
   * @param formData The json of the document
   */
  async function AddThisDocument(schema: any, formData: any) {
    // First add the document
    let newDocument
    try {
      newDocument = await AddDocument(props.schemaId, formData)
    } catch (e: any) {
      // If it fails, make sure the page is set again with the old values
      NotificationError('Error on Add Document', e.message, e)
      // If there is no result, then the rest of the transaction can be skipped.
      return
    }
    // If that succeeds, update the references
    await invalidateSchemas([
      props.schemaId,
      ...(newDocument.versionAt?.referencingSchemas.map(
        (schema) => schema._id
      ) ?? [])
    ])
    await invalidateDocuments([
      newDocument._id,
      ...(newDocument.versionAt?.referencingDocuments.map(
        (document) => document._id
      ) ?? [])
    ])

    let newName
    try {
      // To use generated names, the new name is set to the generated name when using automatic names.
      newName = autoName
        ? await GenerateAutoName(autoName, formData, autoNameInputs)
        : name
        ? new DocumentName(name)
        : undefined
    } catch (e: any) {
      NotificationError('Error when generating name', e.message, e)

      props.onSucceed(newDocument._id)

      return
    }

    // If a name is given, it should also be updated
    if (newName)
      try {
        const addNameResult = await SetDocumentKVP(
          newDocument._id,
          'name',
          newName.getRaw()
        )
        // If the name add succeeded, the new name should be set in the tree
        setName(addNameResult.value)
        await invalidateDocumentNames([newDocument!._id])
      } catch (e: any) {
        // If only the name update fails, the rest can still continue, but the user should be notified
        NotificationError('Error when adding name', e.message, e)
        props.onSucceed(newDocument._id)

        return
      }

    NotificationSuccess('Success', 'Successfully created document.')

    // On succesful creation
    props.onSucceed(newDocument._id)
  }
}

// Styles
const ContentFreeWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`
