import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'

import {
  MutationsAddDocumentMutation,
  MutationsAddDocumentMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsAddDocumentMutation.graphql'
import {
  MutationsAddSchemaMutation,
  MutationsAddSchemaMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsAddSchemaMutation.graphql'
import {
  MutationsRemoveDocumentMutation,
  MutationsRemoveDocumentMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsRemoveDocumentMutation.graphql'
import {
  MutationsRemoveSchemaMutation,
  MutationsRemoveSchemaMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsRemoveSchemaMutation.graphql'
import {
  MutationsSchemaPermissionsMutation,
  MutationsSchemaPermissionsMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsSchemaPermissionsMutation.graphql'
import {
  MutationsUpdateDocumentMutation,
  MutationsUpdateDocumentMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsUpdateDocumentMutation.graphql'
import {
  MutationsUpdateSchemaMutation,
  MutationsUpdateSchemaMutation$data
} from 'src/Modules/Graphql/DocumentManager/__generated__/MutationsUpdateSchemaMutation.graphql'
import { DMRelayEnvironment } from 'src/Modules/Graphql/RelayEnvironments'

/**
 * Add a new schema to the document manager
 * @param json The JsonSchema to be added
 * @returns The new schema
 */
export async function AddSchema(
  json: any
): Promise<MutationsAddSchemaMutation$data['addSchema']> {
  const mutation = graphql`
    mutation MutationsAddSchemaMutation($json: JsonScalar!) {
      addSchema(json: $json) {
        _id
        versionAt {
          referencingSchemas {
            _id
          }
        }
      }
    }
  `

  return new Promise((resolve, reject) => {
    commitMutation<MutationsAddSchemaMutation>(DMRelayEnvironment, {
      mutation: mutation,
      variables: { json: json },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.addSchema)
      }
    })
  })
}

/**
 * Create a new schema version and add it to the document manager
 * @param json The JsonSchema of the new version
 * @returns The new schema version id
 */
export async function UpdateSchema(id: number, json: any) {
  const mutation = graphql`
    mutation MutationsUpdateSchemaMutation(
      $schemaId: Int!
      $json: JsonScalar!
    ) {
      updateSchema(schemaId: $schemaId, json: $json) {
        _id
        referencingSchemas {
          _id
        }
      }
    }
  `

  return new Promise<MutationsUpdateSchemaMutation$data['updateSchema']>(
    (resolve, reject) => {
      commitMutation<MutationsUpdateSchemaMutation>(DMRelayEnvironment, {
        mutation: mutation,
        variables: { schemaId: id, json: json },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.updateSchema)
        }
      })
    }
  )
}

/**
 * Removes a schema from the document manager
 * @param schemaId The id of the schema to be removed
 * @returns A bool indicating success
 */
export async function RemoveSchema(schemaId: number): Promise<undefined> {
  const mutation = graphql`
    mutation MutationsRemoveSchemaMutation($schemaId: Int!) {
      removeSchema(schemaId: $schemaId) {
        schemas
        documents
      }
    }
  `

  const removeResult = await new Promise<
    MutationsRemoveSchemaMutation$data['removeSchema']
  >((resolve, reject) => {
    commitMutation<MutationsRemoveSchemaMutation>(DMRelayEnvironment, {
      mutation: mutation,
      variables: { schemaId: schemaId },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.removeSchema)
      }
    })
  })

  if (
    !removeResult ||
    (removeResult.schemas.length === 0 && removeResult.documents.length === 0)
  )
    return
  else {
    const schemasError = removeResult.schemas.length
      ? `schemas: ${removeResult.schemas.join(', ')}`
      : ''
    const documentsError = removeResult.documents.length
      ? `documents: ${removeResult.documents.join(', ')}`
      : ''
    const andString = schemasError && documentsError ? ' and ' : ''
    const errorString = `${schemasError}${andString}${documentsError}`

    throw new Error(
      `Could not delete schema because it is still referenced by ${errorString}`
    )
  }
}

/**
 * Add a document to the document manager
 * @param schemaId The id of the schema this document implements
 * @param json The json to be added
 * @returns The new document
 */
export async function AddDocument(schemaId: number, json: any) {
  const mutation = graphql`
    mutation MutationsAddDocumentMutation($schemaId: Int!, $json: JsonScalar!) {
      addDocument(schemaId: $schemaId, json: $json) {
        _id
        versionAt {
          referencingSchemas {
            _id
          }
          referencingDocuments {
            _id
          }
        }
      }
    }
  `

  return new Promise<MutationsAddDocumentMutation$data['addDocument']>(
    (resolve, reject) => {
      commitMutation<MutationsAddDocumentMutation>(DMRelayEnvironment, {
        mutation: mutation,
        variables: { schemaId: schemaId, json: json },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.addDocument)
        }
      })
    }
  )
}

/**
 * Update a document to a new version
 * @param documentId Id of the document to update
 * @param json The new document json
 * @returns The new document version
 */
export async function UpdateDocument(documentId: number, json: any) {
  const mutation = graphql`
    mutation MutationsUpdateDocumentMutation($id: Int!, $json: JsonScalar!) {
      updateDocument(documentId: $id, json: $json) {
        _id
        referencingSchemas {
          _id
        }
        referencingDocuments {
          _id
        }
      }
    }
  `

  return new Promise<MutationsUpdateDocumentMutation$data['updateDocument']>(
    (resolve, reject) => {
      commitMutation<MutationsUpdateDocumentMutation>(DMRelayEnvironment, {
        mutation: mutation,
        variables: { id: documentId, json: json },
        onError: reject,
        onCompleted: ($data) => {
          resolve($data.updateDocument)
        }
      })
    }
  )
}

/**
 * Remove a document from the document manager
 * @param documentId Id of the document to remove
 * @returns A bool indicating success
 */
export async function RemoveDocument(documentId: number): Promise<undefined> {
  const mutation = graphql`
    mutation MutationsRemoveDocumentMutation($documentId: Int!) {
      removeDocument(documentId: $documentId)
    }
  `

  const removeResult = await new Promise<
    MutationsRemoveDocumentMutation$data['removeDocument']
  >((resolve, reject) => {
    commitMutation<MutationsRemoveDocumentMutation>(DMRelayEnvironment, {
      mutation: mutation,
      variables: { documentId: documentId },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.removeDocument)
      }
    })
  })

  // If the response is an empty array, the document was removed successfully.
  if (removeResult) return
  // If the response is null, there was an error.
  else
    throw new Error(
      'Could not delete document because it is still referenced by documents'
    )
}

/**
 * Set the schema permissions
 * @param id The schema id.
 * @param read Who can read the schema (undefined means unchanged).
 * @param write Who can write the schema (undefined means unchanged).
 * @returns The schema itself.
 */
export async function SetSchemaPermissions(
  id: number,
  read?: string[],
  write?: string[],
  override?: boolean
) {
  const mutation = graphql`
    mutation MutationsSchemaPermissionsMutation(
      $schemaId: Int!
      $read: [String!]
      $write: [String!]
      $override: Boolean
    ) {
      addSchemaAccess(
        schemaId: $schemaId
        read: $read
        write: $write
        override: $override
      ) {
        _id
      }
    }
  `

  return new Promise<
    MutationsSchemaPermissionsMutation$data['addSchemaAccess']
  >((resolve, reject) => {
    commitMutation<MutationsSchemaPermissionsMutation>(DMRelayEnvironment, {
      mutation: mutation,
      variables: {
        schemaId: id,
        read: read,
        write: write,
        override: override
      },
      onError: reject,
      onCompleted: ($data) => {
        resolve($data.addSchemaAccess)
      }
    })
  })
}
