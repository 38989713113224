import { Menu } from 'antd'
import React from 'react'

import { UseRoute } from 'src/Modules/Common/Hooks/UseRoute'
import { CardTitle } from 'src/Modules/Home/Components/Card/CardTitle'
import { StyledCard } from 'src/Modules/Home/Components/Card/CardWrapper'
import { TitleDropdown } from 'src/Modules/Home/Components/Card/TitleDropdown'
import { CardTitleWrapper } from 'src/Modules/Home/Components/Card/TitleWrapper'
import { DeleteDocumentButton } from 'src/Modules/Home/Components/NodeOptionButtons/DeleteButton/DeleteDocumentButton'
import { useDocumentName } from 'src/Modules/Home/Hooks/DocumentName'

/**
 * Creates card for a document.
 * @param props the id of the document.
 * @returns A card representing the document.
 */
export function DocumentCard(props: { documentId: number }) {
  const route = UseRoute()
  const { data: documentName } = useDocumentName(props.documentId, {
    suspense: true
  })

  return (
    <StyledCard
      title={
        <CardTitleWrapper>
          <CardTitle
            to={`/${route}/document/${props.documentId}`}
            name={documentName?.name?.toString() ?? props.documentId}
          />
          <TitleDropdown>
            <Menu>
              <Menu.Item>
                <DeleteDocumentButton documentId={props.documentId} />
              </Menu.Item>
            </Menu>
          </TitleDropdown>
        </CardTitleWrapper>
      }
      size='small'
    >
      Placeholder
    </StyledCard>
  )
}
