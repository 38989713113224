/**
 * @generated SignedSource<<80fdecb27ab4ab367f52e6c94aee1569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsSendSmsMutation$variables = {
  documentId: string;
};
export type MutationsSendSmsMutation$data = {
  readonly sendSms: string;
};
export type MutationsSendSmsMutation = {
  response: MutationsSendSmsMutation$data;
  variables: MutationsSendSmsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      }
    ],
    "kind": "ScalarField",
    "name": "sendSms",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsSendSmsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsSendSmsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f98dfb2f18af50d8161975ed5c3340a",
    "id": null,
    "metadata": {},
    "name": "MutationsSendSmsMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsSendSmsMutation(\n  $documentId: ID!\n) {\n  sendSms(documentId: $documentId)\n}\n"
  }
};
})();

(node as any).hash = "a84ac725dd655dcf38973718ee575ae4";

export default node;
