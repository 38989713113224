import { AsyncLogicEngine } from 'json-logic-engine'
import { keyBy, uniq, zip } from 'lodash'

import {
  GetDocumentVersionsWithReferences,
  GetSchemaVersionsWithReferencesAndDocuments
} from 'src/Modules/Graphql/DocumentManager/Queries'
import { DocumentToJson, fetchDocuments } from 'src/Modules/Home/Hooks/Document'
import { fetchDocumentNames } from 'src/Modules/Home/Hooks/DocumentName'
import { queryClient } from 'src/Modules/Home/Hooks/QueryClient'
import { fetchSchemas, SchemaToJson } from 'src/Modules/Home/Hooks/Schema'
import { fetchSchemaNames } from 'src/Modules/Home/Hooks/SchemaName'

/**
 * Get the document ids described by the given json logic
 * @param context Schema and Document context
 * @param jsonLogic Json Logic used to get document ids
 * @param parentDocumentId Id of the parent document for the required document ids
 * @returns Array of document ids
 */
export async function GetDocumentIdsWithJsonLogic(
  jsonLogic: any,
  parentDocumentId?: number
): Promise<number[]> {
  const jsonLogicEngine = new AsyncLogicEngine()

  jsonLogicEngine.addMethod(
    'GetSchemas',
    async (param: number[] | { schemaIds?: number[]; orderby?: string[] }) => {
      if (Array.isArray(param)) return await fetchSchemas(param)

      // normal fetch schemas can be dataloaded, with filters it is more complicated.
      // neither need to be dataloaded as the queries are only performed once.
      return (
        await queryClient.fetchQuery(['schemas', param], () =>
          GetSchemaVersionsWithReferencesAndDocuments(param)
        )
      ).map((schemaVersion) => SchemaToJson(schemaVersion))
    }
  )

  jsonLogicEngine.addMethod(
    'GetDocuments',
    async (
      param:
        | number[]
        | { documentIds?: number[]; schemaIds?: number[]; orderby?: string[] }
    ) => {
      if (Array.isArray(param)) return await fetchDocuments(param)

      // normal fetch document can be dataloaded, with filters it is more complicated.
      // neither need to be dataloaded as the queries are only performed once.
      return (
        await queryClient.fetchQuery(['documents', param], () =>
          GetDocumentVersionsWithReferences(param)
        )
      ).map((documentVersion) => DocumentToJson(documentVersion))
    }
  )

  jsonLogicEngine.addMethod(
    'GetSchema',
    async (id: number) => (await fetchSchemas([id]))[0]
  )

  jsonLogicEngine.addMethod(
    'GetDocument',
    async (id: number) => (await fetchDocuments([id]))[0]
  )

  jsonLogicEngine.addMethod(
    'GetSchemaNames',
    async (ids: number[]) => await fetchSchemaNames(ids)
  )

  jsonLogicEngine.addMethod(
    'GetDocumentNames',
    async (ids: number[]) => await fetchDocumentNames(ids)
  )

  jsonLogicEngine.addMethod(
    'GetSchemaName',
    async (id: number) => (await fetchSchemaNames([id]))[0]
  )

  jsonLogicEngine.addMethod(
    'GetDocumentName',
    async (id: number) => (await fetchDocumentNames([id]))[0]
  )

  jsonLogicEngine.addMethod('dataloadSingle', {
    traverse: false,
    method: async <TData, TBatchOut>(
      input: [
        datas: TData[],
        withFunction: (data: TData) => number,
        loadFunction: (ids: number[]) => Promise<TBatchOut[]>
      ],
      context: any,
      _: any,
      engine: AsyncLogicEngine
    ) => {
      //Run datas with the current context.
      const datas: TData[] = await engine.run(input[0], context)

      //Build the with/load function.
      const withFunction = await engine.build(input[1])
      const loadFunction = await engine.build(input[2])

      //Determine the batch inputs.
      const batchInputs: number[] = await Promise.all(
        datas.map((data) => withFunction(data))
      )

      //Load the batch inputs and combine it with the input.
      const batchInput = uniq(batchInputs)
      const batchOutput: TBatchOut[] = await loadFunction(batchInput)
      const inputOutput = keyBy(
        zip(batchInput, batchOutput),
        ([input, _]) => input!
      )

      //Return the batch outputs together with the inputs.
      const dataInput = zip(datas, batchInputs)
      return dataInput.map(([data, batchInput]) => [
        data,
        inputOutput[batchInput!][1]
      ])
    }
  })

  jsonLogicEngine.addMethod('dataloadMultiple', {
    traverse: false,
    method: async <TData, TBatchOut>(
      input: [
        datas: TData[],
        withFunction: (data: TData) => number[],
        loadFunction: (ids: number[]) => Promise<TBatchOut[]>
      ],
      context: any,
      _: any,
      engine: AsyncLogicEngine
    ) => {
      //Run datas with the current context.
      const datas: TData[] = await engine.run(input[0], context)

      //Build the with/load function.
      const withFunction = await engine.build(input[1])
      const loadFunction = await engine.build(input[2])

      //Determine the batch inputs.
      const batchInputs: number[][] = await Promise.all(
        datas.map((data) => withFunction(data))
      )

      //Load the batch inputs and combine it with the input.
      const batchInput = uniq(batchInputs.flat())
      const batchOutput: TBatchOut[] = await loadFunction(batchInput)
      const inputOutput = keyBy(
        zip(batchInput, batchOutput),
        ([input, _]) => input!
      )

      //Return the batch outputs together with the inputs.
      const dataInput = zip(datas, batchInputs)
      return dataInput.map(([data, batchInputs]) => [
        data,
        batchInputs!.map((batchInput) => inputOutput[batchInput][1])
      ])
    }
  })

  const result = await jsonLogicEngine.run(jsonLogic, {
    documentId: parentDocumentId
  })

  return result
}
