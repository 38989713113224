import styled from '@emotion/styled'
import { Input } from 'antd'
import React, { ChangeEvent } from 'react'

/**
 * A styled name input with placeholder
 * @returns A name input jsx
 */
export function NameInput(props: {
  value?: string
  onNameChange: (name: string | undefined) => void
  disabled?: boolean
}) {
  /**
   * Custom on change event to turn an empty string into undefined.
   * @param e the event triggered once the input changes.
   */
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value === '') props.onNameChange(undefined)
    else props.onNameChange(e.currentTarget.value)
  }

  return (
    <PaddedDiv>
      <Input
        disabled={props.disabled}
        placeholder='Name'
        value={props.value}
        onChange={(e) => onChange(e)}
      />
    </PaddedDiv>
  )
}

const PaddedDiv = styled.div`
  padding: 10px 10px 5px 10px;
`
