import { Tooltip } from 'antd'
import React from 'react'

import {
  ForceConfiguration,
  ForceUpdate
} from 'src/Modules/Graphql/Eview/Mutations'
import { MarginButton } from 'src/Modules/Home/Containers/Content/Tabs/Shared/MarginButton'
import {
  executeAsync,
  NotificationError
} from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * A hook to force update firmware in EView
 * @param documentId the documentId of the current document
 * @returns button to update firmware
 */
export function EviewUpdate(props: { documentId: number }) {
  return (
    <Tooltip title='Force firmware update on the button'>
      <MarginButton size='small' onClick={UpdateFirmwareHash}>
        Update Firmware
      </MarginButton>
    </Tooltip>
  )

  /**
   * Request EView to force update the firmware hash
   */
  async function UpdateFirmwareHash() {
    const success = await executeAsync(
      async () => await ForceUpdate(props.documentId),
      'Force updating firmware failed'
    )

    success
      ? NotificationSuccess('Firmware update', 'Firmware update pending')
      : NotificationError('Firmware update', 'Firmware update already pending')
  }
}

/**
 * A hook to force update firmware in EView
 * @param documentId the documentId of the current document
 * @returns button to update firmware
 */
export function EviewConfiguration(props: { documentId: number }) {
  return (
    <Tooltip title='Force configuration update on the button'>
      <MarginButton size='small' onClick={UpdateConfigurationHash}>
        Update Configuration
      </MarginButton>
    </Tooltip>
  )

  /**
   * Request EView to force update the firmware hash
   */
  async function UpdateConfigurationHash() {
    const success = await executeAsync(
      async () => await ForceConfiguration(props.documentId),
      'Updating configuration failed'
    )

    success
      ? NotificationSuccess(
          'Configuration update',
          'Configuration update pending'
        )
      : NotificationError(
          'Configuration update',
          'Configuration update already pending'
        )
  }
}
