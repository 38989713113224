import React, { useEffect, useState } from 'react'

import {
  GetDocumentJson,
  GetSchemaJson
} from 'src/Modules/Graphql/DocumentManager/Queries'
import { GetUiSchema } from 'src/Modules/Graphql/MetaDataManager/Queries'
import FormCodeEditor from 'src/Modules/Home/Components/Editors/FormCodeEditor'
import { useDocument } from 'src/Modules/Home/Hooks/Document'
import { useSchemaName } from 'src/Modules/Home/Hooks/SchemaName'
import CenteredCustomSpinner from 'src/Modules/Utilities/Components/Centering/CenteredCustomSpinner'

/**
 * A document code editor
 * @returns A document code editor
 */
export function NewDocumentCodeEditor(props: {
  schemaId: number
  onSubmit: (schema: any, json: any) => Promise<void>
}) {
  return (
    <DocumentCodeEditor schemaId={props.schemaId} onSubmit={props.onSubmit} />
  )
}

/**
 * A document code editor
 * @returns A document code editor
 */
export function ExistingDocumentCodeEditor(props: {
  documentId: number
  onSubmit: (schema: any, json: any) => Promise<void>
}) {
  const [formData, setFormData] = useState<any>()

  const { data: document } = useDocument(props.documentId)
  const schemaId = document?.schemaId

  useEffect(() => {
    /** Async function wrapper */
    async function effectFunction() {
      // Get json
      const document = await GetDocumentJson(props.documentId)
      setFormData(document?.json)
    }

    effectFunction()
  }, [props.documentId])

  if (schemaId === undefined || formData === undefined)
    return <CenteredCustomSpinner />

  return (
    <DocumentCodeEditor
      schemaId={schemaId}
      formData={formData}
      onSubmit={props.onSubmit}
    />
  )
}

/**
 * A document code editor
 * @returns A document code editor
 */
function DocumentCodeEditor(props: {
  schemaId: number
  formData?: any
  onSubmit: (schema: any, json: any) => Promise<void>
}) {
  const [schema, setSchema] = useState<any>()
  const [uiSchema, setUiSchema] = useState<any>()
  const { data: schemaName } = useSchemaName(props.schemaId)

  useEffect(() => {
    /** Async function wrapper */
    async function effectFunction() {
      //TODO use react query
      const schemaJsonTask = GetSchemaJson(props.schemaId)
      const uiSchemaTask = GetUiSchema(props.schemaId)
      const [uiSchema, schemaJson] = await Promise.all([
        uiSchemaTask,
        schemaJsonTask
      ])
      setSchema(schemaJson)
      setUiSchema(uiSchema ?? null)
    }

    effectFunction()
  }, [props.schemaId])

  if (!schema || uiSchema === undefined) return <CenteredCustomSpinner />

  return (
    <FormCodeEditor
      schema={schema}
      uiSchema={uiSchema ?? undefined}
      formData={props.formData}
      onSubmit={(json) => props.onSubmit(schema, json)}
      submitText={`Save ${schemaName?.value ?? 'document'}`}
    />
  )
}
