/**
 * @generated SignedSource<<e51e45047c66954e85603f35c4c6968c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$variables = {
  at?: string | null;
  documentIds?: ReadonlyArray<number> | null;
  referencedBySchemaIds?: ReadonlyArray<number> | null;
  referencingSchemaIds?: ReadonlyArray<number> | null;
};
export type QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$data = {
  readonly documents: ReadonlyArray<{
    readonly _id: number;
    readonly referencedByDocumentVersions: ReadonlyArray<{
      readonly document: {
        readonly _id: number;
        readonly schema: {
          readonly _id: number;
        };
      };
    }>;
    readonly versionAt: {
      readonly json: any;
      readonly referencingDocuments: ReadonlyArray<{
        readonly _id: number;
        readonly schema: {
          readonly _id: number;
        };
      }>;
    } | null;
  }>;
};
export type QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery = {
  response: QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$data;
  variables: QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "referencedBySchemaIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "referencingSchemaIds"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "at",
  "variableName": "at"
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Schema",
    "kind": "LinkedField",
    "name": "schema",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "documentIds"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": [
          (v5/*: any*/)
        ],
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "versionAt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "json",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "schemaIds",
                "variableName": "referencingSchemaIds"
              }
            ],
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "referencingDocuments",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "schemaIds",
            "variableName": "referencedBySchemaIds"
          }
        ],
        "concreteType": "DocumentVersion",
        "kind": "LinkedField",
        "name": "referencedByDocumentVersions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "b923690fa396a06e39d54fac1054a763",
    "id": null,
    "metadata": {},
    "name": "QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery",
    "operationKind": "query",
    "text": "query QueriesGetDocumentsJsonAndReferencesBySchemaIdQuery(\n  $documentIds: [Int!]\n  $referencingSchemaIds: [Int!]\n  $referencedBySchemaIds: [Int!]\n  $at: DateTime\n) {\n  documents(ids: $documentIds) {\n    _id\n    versionAt(at: $at) {\n      json\n      referencingDocuments(at: $at, schemaIds: $referencingSchemaIds) {\n        _id\n        schema {\n          _id\n        }\n      }\n    }\n    referencedByDocumentVersions(at: $at, schemaIds: $referencedBySchemaIds) {\n      document {\n        _id\n        schema {\n          _id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "98e5926f7e32304fa25467ddfe99816e";

export default node;
