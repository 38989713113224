import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { FieldTemplateProps } from '@rjsf/core'
import { Typography } from 'antd'
import { useState } from 'react'

/**
 * Custom link field template for RSJF
 * @param props RSJF Field Template Props
 * @returns Field component
 */
export default function LinkField(props: FieldTemplateProps) {
  const value = props.formData as string | undefined

  // Start with edit mode if the link has never been set before, otherwise start with link mode (when viewing existing document)
  const [edit, setEdit] = useState(value === undefined)

  return (
    <div className={props.classNames}>
      <label htmlFor={props.id}>
        {props.label}
        {props.required ? '*' : null}
      </label>
      {edit ? (
        <StyledEyeOutlined onClick={() => setEdit(false)} />
      ) : (
        <StyledEditOutlined onClick={() => setEdit(true)} />
      )}
      {props.description}
      {edit ? (
        // Show default string input for RSJF
        props.children
      ) : (
        <StyledLink target='_blank' href={value}>
          {value}
        </StyledLink>
      )}
      {props.errors}
      {props.help}
    </div>
  )
}

const StyledLink = styled(Typography.Link)`
  display: block;
`
const StyledEditOutlined = styled(EditOutlined)`
  margin-left: 10px;
  color: gray;
`

const StyledEyeOutlined = styled(EyeOutlined)`
  margin-left: 10px;
  color: gray;
`
