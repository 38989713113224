import { useLocation, useNavigate } from 'react-router-dom'

/**
 * A navigation hook.
 * @returns a function to navigate while keeping params.
 */
export function UseNavigateWithParam() {
  const navigate = useNavigate()
  const location = useLocation()

  /**
   * Navigate to the given url, while keeping query params.
   * @param to the url to navigate to.
   */
  function NavigateWithParam(to: string) {
    navigate({ pathname: to, search: location.search })
  }

  return NavigateWithParam
}
