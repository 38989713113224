import styled from '@emotion/styled'
import React from 'react'

import { NoMarginDivider } from 'src/Modules/Home/Components/Title/NoMarginDivider'

/**
 * A divided component.
 * @param props The top and children elements.
 * @returns A component, with the top and children divided by a margin.
 */
export default function TopContentWrapper(props: {
  top: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <AutoTopGrid>
      <div>{props.top}</div>
      <NoMarginDivider type='horizontal' />
      <HeightDiv>{props.children}</HeightDiv>
    </AutoTopGrid>
  )
}

const AutoTopGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }
`

const HeightDiv = styled.div`
  overflow: hidden;
`
