import { blue, green, red, yellow } from '@ant-design/colors'
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  SearchOutlined,
  WarningTwoTone
} from '@ant-design/icons'
import { Button, Input, Space, Tooltip, Table } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { CSSProperties, useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { GetPaginatedAlerts } from 'src/Modules/Graphql/LoggingDatabase/Queries'
import { QueriesPaginatedAlertsQuery$data } from 'src/Modules/Graphql/LoggingDatabase/__generated__/QueriesPaginatedAlertsQuery.graphql'
import { TabContentWrapper } from 'src/Modules/Home/Components/ContentWrappers/TabContentWrapper'
import DateColumn from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/DateColumn'
import DocumentIdColumn from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/DocumentIdColumn'
import UseOneOrMany from 'src/Modules/Home/Containers/Content/Tabs/Shared/Table/UseIds'
import CustomSpinner from 'src/Modules/Utilities/Components/CustomSpinner'

type status = NonNullable<
  NonNullable<QueriesPaginatedAlertsQuery$data['paginatedAlerts']>['items']
>[0]

/**
 * A hook to send sms to the eview integration
 * @param documentId the documentId of the current document
 * @returns undefined if the document is not an eview button or a function to send an sms
 */
export default function StatusPage(props: { documentIds: number | number[] }) {
  const documentIds = UseOneOrMany(props.documentIds)
  const queryClient = useQueryClient()

  type PageInfo =
    | {
        endCursor: string | null
        hasNextPage: boolean
      }
    | undefined

  /**
   * Function to fetch the next alerts
   * @param param page params passed by infinite query - in our case the end cursor of the last page
   * @returns Page info containing the end cursor/has next page and the alerts
   */
  async function fetchAlerts({
    pageParam = undefined
  }): Promise<[PageInfo, readonly status[]]> {
    const result = (await GetPaginatedAlerts(documentIds, 20, pageParam))
      ?.paginatedAlerts
    return [result?.pageInfo, result?.items ?? []]
  }

  const { data, fetchNextPage, isFetching, status, hasNextPage } =
    useInfiniteQuery(['alerts', documentIds], fetchAlerts, {
      getNextPageParam: (lastPage, _) =>
        lastPage[0]?.hasNextPage ? lastPage[0].endCursor : undefined,
      staleTime: Infinity
    })

  const allAlerts = useMemo(
    () => data?.pages.flatMap(([, page]) => page),
    [data]
  )

  const columns = [
    ...(typeof props.documentIds === 'object'
      ? [DocumentIdColumn(props.documentIds)]
      : []),
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: AlertEnum) => (
        <Tooltip overlay={type}>{typeToIcon(type)}</Tooltip>
      ),
      filters: [
        { value: 'Success', text: typeToIcon('Success') },
        { value: 'Info', text: typeToIcon('Info') },
        { value: 'Warning', text: typeToIcon('Warning') },
        { value: 'Danger', text: typeToIcon('Danger') }
      ],
      onFilter: (value: any, record: status) =>
        record.type.indexOf(value.toUpperCase()) === 0
    },
    {
      title: 'DateTime',
      dataIndex: 'dateTime',
      key: 'datetime',
      ...DateColumn<status>()
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ...getSearchProps('title')
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ...getSearchProps('message')
    }
  ]

  return (
    <TabContentWrapper
      bottom={
        <>
          <Button
            type='primary'
            onClick={async () => await fetchNextPage()}
            loading={isFetching}
            disabled={!hasNextPage}
          >
            Load More
          </Button>

          <Button
            onClick={() => {
              queryClient.removeQueries(['alerts', documentIds])
              fetchNextPage()
            }}
            loading={isFetching}
          >
            Refresh
          </Button>
        </>
      }
    >
      <Table
        loading={{
          spinning: status === 'loading',
          indicator: <CustomSpinner />
        }}
        columns={columns}
        dataSource={
          allAlerts?.map((status) => ({
            ...status,
            key: status!._id
          })) ?? []
        }
        rowKey='_id'
        pagination={false}
      />
    </TabContentWrapper>
  )

  /**
   * Get properties for search input filter
   * @returns Properties for the dropdown and filtering
   */
  function getSearchProps(dataIndex: string) {
    return {
      onFilter: (value: any, record: any) => {
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (text: string) => text,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={() => {
                confirm()
              }}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) clearFilters()
              }}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    }
  }

  /**
   * Change the logging status type to the antdalert type
   * @param type the logging type
   * @returns the antd type
   */
  function typeToIcon(type: AlertEnum) {
    const style: CSSProperties = {
      fontSize: '20px',
      paddingRight: '10px'
    }

    switch (type) {
      case 'Danger':
        return <WarningTwoTone twoToneColor={red.primary} style={style} />
      case 'Info':
        return <InfoCircleTwoTone twoToneColor={blue.primary} style={style} />
      case 'Success':
        return <CheckCircleTwoTone twoToneColor={green.primary} style={style} />
      case 'Warning':
        return (
          <ExclamationCircleTwoTone
            twoToneColor={yellow.primary}
            style={style}
          />
        )
    }
  }
}

type AlertEnum = 'Danger' | 'Info' | 'Success' | 'Warning'
