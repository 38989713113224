import { UploadOutlined } from '@ant-design/icons'
import { WidgetProps, utils } from '@rjsf/core'
import { Button, Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import download from 'downloadjs'
import React from 'react'

/**
 * File widget that loads the file from a given json, as well as allowing the file to be deleted.
 * @param props Widget props from rjsf
 * @returns The custom file widget
 */
export default function CustomFileWidget(props: WidgetProps) {
  /**
   * Handles change of file upload
   * @param info Paramters of the file upload change event
   */
  async function OnChange(info: UploadChangeParam<UploadFile<any>>) {
    //If multiple files are allowed, convert them all and call onchange to update the form
    if (props.multiple) {
      const dataUrlPromises = info.fileList.map(
        async (file) => await BlobToDataUrl(file.name, file.originFileObj)
      )
      const dataUrls = await Promise.all(dataUrlPromises)
      props.onChange(dataUrls)
    } else {
      const file = info.fileList[0]
      const dataUrl = await BlobToDataUrl(file?.name, file?.originFileObj)
      props.onChange(dataUrl)
    }
  }

  const defaultFileList =
    props.value === undefined
      ? []
      : props.multiple
      ? props.value
          .filter((dataUrl: string) => dataUrl !== undefined)
          .map((dataUrl: string) => DataUrlToBlob(dataUrl))
      : props.value
      ? [DataUrlToBlob(props.value)]
      : undefined

  return (
    <Upload.Dragger
      multiple={props.multiple}
      maxCount={props.schema.maxItems ?? 1}
      disabled={props.disabled || props.readonly}
      defaultFileList={defaultFileList}
      onChange={(info) => OnChange(info)}
      beforeUpload={() => false} //Prevent antd from trying to upload the file (and fail)
      onPreview={(e) => OnPreview(e)}
    >
      <Button icon={<UploadOutlined />}></Button>
      <p>
        Click or drag file to this area to upload{' '}
        {props.required !== true && 'zero or'}{' '}
        {props.multiple
          ? `${props.schema.minItems ?? '1'} to ${
              props.schema.maxItems ?? 'a lot'
            }`
          : 'one'}{' '}
        file(s)
      </p>
    </Upload.Dragger>
  )

  /**
   * Handles a click on the preview
   * @param e Preview event data
   */
  async function OnPreview(e: UploadFile<any>) {
    //Convert the blob to url and download it to the computer.
    const dataUrl = await BlobToDataUrl(e.name, e.originFileObj)
    if (dataUrl) download(dataUrl, e.name)
    else console.error('dataUrl download failed')
  }

  /**
   * Convert a blob to a dataUrl
   * @param blob The blob to convert
   * @returns A dataUrl string
   */
  function BlobToDataUrl(
    fileName?: string,
    blob?: Blob
  ): Promise<string | undefined> {
    return new Promise((resolve) => {
      // return undefined file input is undefined
      if (fileName === undefined || blob === undefined) resolve(undefined)
      else {
        const reader = new FileReader()
        reader.onload = (e) => {
          // We need a string, filereader does not know this, hence the typeof
          if (!e.target || typeof e.target.result !== 'string')
            resolve(undefined)
          else
            resolve(
              //Insert a name, to retrieve it in download.
              e.target.result.replace(
                ';base64',
                `;name=${encodeURIComponent(fileName)};base64`
              )
            )
        }
        reader.onerror = () => resolve(undefined)
        reader.readAsDataURL(blob)
      }
    })
  }
}

/**
 * Convert the dataUrl to a blob to store in the uploads
 * @param dataUrl dataUrl to convert to blob
 * @returns a blob
 */
export function DataUrlToBlob(dataUrl: string) {
  const { name, blob } = utils.dataURItoBlob(dataUrl)
  // Get back the name and decode it
  return {
    uid: `${Date.now()}`,
    name: decodeURIComponent(name),
    status: 'done',
    originFileObj: blob
  }
}
