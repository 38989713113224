/**
 * @generated SignedSource<<40563ffa30a7a4b5e9586975b63f13ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AlertEnum = "Danger" | "Info" | "Success" | "Warning" | "%future added value";
export type QueriesPaginatedAlertsQuery$variables = {
  after?: string | null;
  documentIds?: ReadonlyArray<number> | null;
  first?: number | null;
};
export type QueriesPaginatedAlertsQuery$data = {
  readonly paginatedAlerts: {
    readonly items: ReadonlyArray<{
      readonly _id: any;
      readonly dateTime: string;
      readonly documentId: number;
      readonly message: string;
      readonly title: string;
      readonly type: AlertEnum;
    }> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  } | null;
};
export type QueriesPaginatedAlertsQuery = {
  response: QueriesPaginatedAlertsQuery$data;
  variables: QueriesPaginatedAlertsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "documentIds"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "ModelAlertConnection",
    "kind": "LinkedField",
    "name": "paginatedAlerts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "documentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesPaginatedAlertsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesPaginatedAlertsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "598edf203fdfda5595b30080cccf017c",
    "id": null,
    "metadata": {},
    "name": "QueriesPaginatedAlertsQuery",
    "operationKind": "query",
    "text": "query QueriesPaginatedAlertsQuery(\n  $documentIds: [Int!]\n  $first: Int\n  $after: String\n) {\n  paginatedAlerts(documentIds: $documentIds, first: $first, after: $after) {\n    items {\n      _id\n      documentId\n      dateTime\n      type\n      title\n      message\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc3af8de3edf65c094a8e75d0d8164f9";

export default node;
