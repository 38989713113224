/**
 * @generated SignedSource<<7a0780f806fbc37028604b37766f49c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationsSetSchemaKVPMutation$variables = {
  id: number;
  key: string;
  value: string;
};
export type MutationsSetSchemaKVPMutation$data = {
  readonly setSchemaKVP: {
    readonly _id: number;
    readonly value: string;
  };
};
export type MutationsSetSchemaKVPMutation = {
  response: MutationsSetSchemaKVPMutation$data;
  variables: MutationsSetSchemaKVPMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "SchemaKvp",
    "kind": "LinkedField",
    "name": "setSchemaKVP",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MutationsSetSchemaKVPMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MutationsSetSchemaKVPMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac0329fec3bac41d101e6a5c635c430d",
    "id": null,
    "metadata": {},
    "name": "MutationsSetSchemaKVPMutation",
    "operationKind": "mutation",
    "text": "mutation MutationsSetSchemaKVPMutation(\n  $id: Int!\n  $key: String!\n  $value: String!\n) {\n  setSchemaKVP(id: $id, key: $key, value: $value) {\n    _id\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c2c9558318a94ede430adf4a83bda1b";

export default node;
