import { PublicClientApplication } from '@azure/msal-browser'

import { Authority, InterfaceClientId } from 'src/Modules/Utilities/Environment'

export const MsalInstance = new PublicClientApplication({
  auth: {
    authority: Authority,
    clientId: InterfaceClientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
})
