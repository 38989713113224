import { Tooltip } from 'antd'
import React from 'react'

import {
  ActivateSimCard,
  DeactivateSimCard
} from 'src/Modules/Graphql/OliviaSimCard/Mutations'
import { MarginButton } from 'src/Modules/Home/Containers/Content/Tabs/Shared/MarginButton'
import { executeAsync } from 'src/Modules/Utilities/ErrorHandler'
import { NotificationSuccess } from 'src/Modules/Utilities/SuccessHandler'

/**
 * A hook to activate a sim card
 * @param schemaId the schemaId of the current document
 * @param documentId the documentId of the current document
 * @returns A component
 */
export function ActivateOliviaSimcard(props: { documentId: number }) {
  return (
    <Tooltip title='Activate the sim card'>
      <MarginButton size='small' onClick={() => Activate()}>
        Activate
      </MarginButton>
    </Tooltip>
  )

  /**
   * Activate the sim card
   */
  async function Activate() {
    await executeAsync(
      async () => await ActivateSimCard(props.documentId),
      'Activating sim failed'
    )
    NotificationSuccess('Activated sim', 'Activated the sim card')
  }
}

/**
 * A hook to deactivate a sim card
 * @param schemaId the schemaId of the current document
 * @param documentId the documentId of the current document
 * @returns A component
 */
export function DeactivateOliviaSimcard(props: { documentId: number }) {
  return (
    <Tooltip title='Deactivate the sim card'>
      <MarginButton size='small' onClick={() => Deactivate()}>
        Deactivate
      </MarginButton>
    </Tooltip>
  )

  /**
   * Deactivate the sim card
   */
  async function Deactivate() {
    await executeAsync(
      async () => await DeactivateSimCard(props.documentId),
      'Deactivating sim failed'
    )
    NotificationSuccess('Deactivated sim', 'Deactivated the sim card')
  }
}
